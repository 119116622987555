import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import { IoCloseOutline } from 'react-icons/io5'
import { useRagContext } from '../../../../contexts/RagContext'
import axios from 'axios'

interface EditConceptModalProps {
  open?: boolean
  handleClose?: () => void
  onEdit?: () => void
  concept: any
}

const EditConceptModal: React.FC<EditConceptModalProps> = ({
  open,
  handleClose,
  onEdit,
  concept,
}) => {
  const { getConceptFolders } = useRagContext()
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  useEffect(() => {
    if (concept && open) {
      setTitle(concept.concept_title)
      setDescription(concept.concept_description)
    } else {
      setTitle('')
      setDescription('')
    }
  }, [concept, open])

  const handleRenameConcept = async () => {
    if (title === '') return
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/editConcept',
        {
          id: concept?.id,
          concept_title: title,
          concept_description: description,
        },
      )
      if (response?.status === 200) {
        getConceptFolders()
        if (handleClose) handleClose()
        if (onEdit) onEdit()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      open={Boolean(open)}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[436px] bg-white rounded-[10px] shadow-lg">
        <div className="py-4 px-6 flex items-center justify-between border-b border-solid border-[#E4E4E7] gap-4">
          <div className="">
            <div className="text-lg leading-[22px] font-bold text-[#18181B] mb-1">
              Edit concept
            </div>
          </div>
          <button
            className="w-10 h-10 border border-solid border-[#D4D4D8] flex items-center justify-center flex-none rounded-full"
            onClick={() => {
              if (handleClose) handleClose()
            }}
          >
            <IoCloseOutline className="w-6 h-6 text-[#09090B]" />
          </button>
        </div>
        <div className="p-6">
          <div className="w-full flex flex-col gap-4 mb-8">
            <div className="w-full">
              <label htmlFor="edit-concept-title" className="px-1 mb-1">
                Title*
              </label>
              <input
                type="text"
                id="edit-concept-title"
                name="edit-concept-title"
                className="w-full border border-solid border-[#D4D4D8] outline-none px-4 py-3 rounded-lg h-12 text-sm font-medium text-[#3F3F46]"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="w-full">
              <label htmlFor="edit-concept-description" className="px-1 mb-1">
                Description
              </label>
              <textarea
                id="edit-concept-description"
                name="edit-concept-description"
                className="w-full border border-solid border-[#D4D4D8] outline-none px-4 py-3 rounded-lg h-[168px] text-sm font-medium text-[#3F3F46]"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
          <div className="flex gap-4">
            <button
              className="outline-none border border-solid border-[#71717A] h-12 flex items-center justify-center rounded-lg w-full"
              onClick={() => {
                if (handleClose) handleClose()
              }}
            >
              <span className="text-base font-bold text-[#71717A]">Cancel</span>
            </button>
            <button
              className="outline-none bg-[#3B82F6] h-12 flex items-center justify-center rounded-lg w-full"
              onClick={() => handleRenameConcept()}
            >
              <span className="text-base font-bold text-white">Save</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default EditConceptModal
