import React, { createContext, useContext, useMemo, useState } from 'react'
import axios from 'axios'
import { useUserContext } from './UserContext'
import { useLocation } from 'react-router'
import { generateUniqueId } from '../utils'

interface RagContextType {
  folderList: any[]
  selectedFiles: any
  conceptModalVisible: boolean | undefined
  selectedConceptId: string | undefined | null
  fileManagerModalVisible: boolean
  conceptManagerModalVisible: boolean
  setting: any
  conceptAddFolderVisible: boolean
  conceptAddFolderSelection: string
  conceptFolders: any[]
  socket: any
  socketId: any
  chatTopicId: any
  setChatTopicId: (arg: any) => void
  pageName: any
  retriever: any
  setRetriever: (arg: any) => void
  subRetriever: any
  setSubRetriever: (arg: any) => void
  selectedUploadFiles: any
  activityLogs: any
  activityLogsModalVisible: any
  shareFeedbackVisible: boolean
  openSettingsModal: boolean
  setFolderList: (arg: any) => void
  setSelectedFiles: (arg: any) => void
  toggleSelectFolder: (arg: any, topFolderId: string) => void
  toggleSelectFile: (
    filename: any,
    folderId: string,
    topFolderId: string,
  ) => void
  isSelectedFolder: (arg: any) => boolean
  isSelectedFile: (filename: any, folderId: string) => boolean
  getFilePath: (filename: any, folderId: string) => string
  openFileManagerModal: () => void
  closeFileManagerModal: () => void
  openConceptManagerModal: () => void
  closeConceptManagerModal: () => void
  getSettingData: () => void
  openConceptAddFolderModal: (type: string, selection?: string) => void
  closeConceptAddFolderModal: () => void
  getConceptFolders: () => void
  setSocketInterface: (socket: any, socketId: any) => void
  fetchFolders: () => void
  setSelectedUploadFiles: (arg: any) => void
  openActivityLogsModal: (log: any) => void
  closeActivityLogsModal: () => void
  openShareFeedbackModal: (arg: any) => void
  closeShareFeedbackModal: () => void
  handleSettingModalOpen: () => void
  handleCloseSettingsModal: () => void
}

const RagContext = createContext<RagContextType | undefined>(undefined)

export const RagContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation()

  // Get search params from the URL

  const [folderList, setFolderList] = useState<any[]>([])
  const [selectedFiles, setSelectedFiles] = useState<any>([])
  const [lastSelectedTopFolderId, setLastSelectedTopFolderId] = useState<any>(
    '',
  )
  const [socket, setSocket] = useState(null)
  const [pageName, setPageName] = useState<string>('Chat')
  const [socketId, setSocketId] = useState('')
  const [chatTopicId, setChatTopicId] = useState('')
  const [retriever, setRetriever] = useState<
    'selected_source' | 'tavily' | 'google_patents' | 'serpapi'
  >('selected_source')
  const [subRetriever, setSubRetriever] = useState<
    'google_scholar' | 'arxiv' | 'sciencedirect' | ''
  >('')
  const conceptModalVisible = useMemo(() => {
    if (location) {
      const searchParams = new URLSearchParams(location?.search)
      if (
        searchParams.get('render_type') === 'modal' &&
        searchParams.get('render_modal') === 'concept_modal' &&
        searchParams.get('concept_id')
      ) {
        return true
      } else {
        return false
      }
    }
  }, [location])

  const selectedConceptId = useMemo(() => {
    if (location) {
      const searchParams = new URLSearchParams(location?.search)
      if (
        searchParams.get('render_type') === 'modal' &&
        searchParams.get('render_modal') === 'concept_modal' &&
        searchParams.get('concept_id')
      ) {
        return searchParams.get('concept_id')
      } else {
        return ''
      }
    }
  }, [location])
  const [setting, setSetting] = useState<any>({})
  const { user } = useUserContext()
  const [fileManagerModalVisible, setFileManagerModalVisible] = useState<any>(
    false,
  )
  const [conceptManagerModalVisible, setConceptManagerModalVisible] = useState<
    any
  >(false)
  const [conceptAddFolderVisible, setConceptAddFolderVisible] = useState<any>(
    false,
  )
  const [conceptAddFolderSelection, setConceptAddFolderSelection] = useState<
    any
  >('')
  const [conceptFolders, setConceptFolders] = useState<any[]>([])
  const [selectedUploadFiles, setSelectedUploadFiles] = useState<any>([])

  const [activityLogsModalVisible, setActivityLogsModalVisible] = useState(
    false,
  )
  const [activityLogs, setActivityLogs] = useState<any>([])
  const [shareFeedbackVisible, setShareFeedbackVisible] = useState<any>(false)
  const [openSettingsModal, setOpenSettingsModal] = useState(false)

  const handleSettingModalOpen = () => {
    setOpenSettingsModal(true)
  }

  const handleCloseSettingsModal = () => {
    setOpenSettingsModal(false)
  }
  const setSocketInterface = (socket: any, socketId: any) => {
    setSocket(socket)
    setSocketId(socketId)
  }

  const toggleSelectFolder = (folder_id: any, topFolderId: string) => {
    let tempSelectedFiles: any
    if (lastSelectedTopFolderId !== topFolderId) {
      tempSelectedFiles = []
    } else {
      tempSelectedFiles = JSON.parse(JSON.stringify(selectedFiles))
    }

    folderList.forEach((parentFolder) => {
      toggleSelectSubFolder(
        parentFolder?.structure[Object.keys(parentFolder?.structure)[0]],
        folder_id,
        isSelectedFolder(folder_id),
        tempSelectedFiles,
      )
    })
    setLastSelectedTopFolderId(topFolderId)
    setSelectedFiles(tempSelectedFiles)
  }

  const toggleSelectSubFolder = (
    folder: any,
    folder_id: any,
    selected: boolean,
    selectedFiles: any,
  ) => {
    if (folder.id === folder_id) {
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key]
        if (item.isFolder) {
          toggleSelectSubFolder(item, item.id, selected, selectedFiles)
        } else {
          let index = selectedFiles.findIndex(
            (e: any) => e.file_name === key && e.folder_id === folder_id,
          )
          if (selected) {
            selectedFiles.splice(index, 1)
          } else {
            if (index === -1)
              selectedFiles.push({
                file_name: key,
                folder_id: folder_id,
              })
          }
        }
      })
    } else {
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key]
        if (item.isFolder) {
          toggleSelectSubFolder(item, folder_id, selected, selectedFiles)
        }
      })
    }
  }

  const toggleSelectFile = (
    file_name: any,
    folder_id: string,
    topFolderId: string,
  ) => {
    let tempSelectedFiles: any
    if (lastSelectedTopFolderId !== topFolderId) {
      tempSelectedFiles = []
    } else {
      tempSelectedFiles = JSON.parse(JSON.stringify(selectedFiles))
    }
    let index = tempSelectedFiles.findIndex(
      (e: any) => e.file_name === file_name && e.folder_id === folder_id,
    )
    if (index !== -1) {
      tempSelectedFiles.splice(index, 1)
    } else {
      tempSelectedFiles.push({
        file_name,
        folder_id,
      })
    }
    setLastSelectedTopFolderId(topFolderId)
    setSelectedFiles(tempSelectedFiles)
  }

  const isSelectedFolder = (folder_id: any): boolean => {
    let isSelected = false
    folderList.forEach((parentFolder) => {
      if (
        isSelectedSubFolder(
          parentFolder?.structure[Object.keys(parentFolder?.structure)[0]],
          folder_id,
        )
      ) {
        isSelected = true
      }
    })

    return isSelected
  }

  const isSelectedSubFolder = (folder: any, folder_id: any): boolean => {
    if (folder.id === folder_id) {
      let isSelected = true
      if (Object.keys(folder.children).length === 0) return false
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key]
        if (
          !(
            (item.isFolder && isSelectedSubFolder(item, item.id)) ||
            (!item.isFolder && isSelectedFile(key, folder_id))
          )
        ) {
          isSelected = false
        }
      })
      return isSelected
    } else {
      let isSelected = false
      if (Object.keys(folder.children).length === 0) return false
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key]
        if (item.isFolder && isSelectedSubFolder(item, folder_id)) {
          isSelected = true
        }
      })
      return isSelected
    }
  }

  const isSelectedFile = (file_name: any, folder_id: string): boolean => {
    if (
      selectedFiles.find(
        (e: any) => e.file_name === file_name && e.folder_id === folder_id,
      )
    ) {
      return true
    } else {
      return false
    }
  }

  const getSettingData = async () => {
    try {
      const result = await axios.post(
        process.env.REACT_APP_API_URL + 'api/getSetting',
        {
          team_id: user?.organizationMemberships[0]?.organization.id,
        },
      )
      setSetting(result.data.data)
    } catch (error) {}
  }

  const openFileManagerModal = () => {
    setFileManagerModalVisible(true)
  }

  const closeFileManagerModal = () => {
    setFileManagerModalVisible(false)
  }

  const getFilePath = (file_name: string, folder_id: string): string => {
    let result = ''

    for (let index = 0; index < folderList.length; index++) {
      const parentFolder = folderList[index]
      const subPath = getFilePathSub(
        parentFolder?.structure[Object.keys(parentFolder?.structure)[0]],
        file_name,
        folder_id,
        0,
      )
      if (subPath !== '') {
        result = `upload/${user?.organizationMemberships[0]?.organization.id}/${parentFolder.id}/${subPath}`
        break
      }
    }
    return result
  }

  const getFilePathSub = (
    folder: any,
    file_name: string,
    folder_id: string,
    deepth: number,
  ): string => {
    if (folder.id === folder_id) {
      if (deepth === 0) {
        return `${folder.id}/${file_name}`
      } else {
        return file_name
      }
    } else {
      let result = ''
      for (const key in folder.children) {
        const item = folder.children[key]
        if (item.isFolder) {
          const resp = getFilePathSub(item, file_name, folder_id, deepth + 1)

          if (resp !== '') {
            result = `${item.id}/${resp}`
            break
          }
        }
      }
      if (deepth === 0 && result !== '') {
        result = `${folder.id}/${result}`
      }
      return result
    }
  }

  const openConceptManagerModal = () => {
    setConceptManagerModalVisible(true)
  }

  const closeConceptManagerModal = () => {
    setConceptManagerModalVisible(false)
  }

  const openConceptAddFolderModal = (type: string, selection?: string) => {
    setConceptAddFolderVisible(true)
    if (type === 'selected_source') {
      setConceptAddFolderSelection('')
    } else if (type === 'window_selection') {
      setConceptAddFolderSelection(selection)
    }
  }

  const closeConceptAddFolderModal = () => {
    setConceptAddFolderVisible(false)
    setConceptAddFolderSelection('')
  }

  const getConceptFolders = async () => {
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/getConceptFolders',
        {
          team_id: user?.organizationMemberships[0]?.organization.id,
        },
      )
      if (response?.status === 200) {
        if (response?.data) {
          setConceptFolders(response.data.data)
        } else {
          setConceptFolders([])
        }
      }
      return response
    } catch (error) {
      console.log(error)
    }
  }

  const fetchFolders = async () => {
    try {
      const result = await axios.post(
        process.env.REACT_APP_API_URL + 'api/getFolders',
        {
          team_id: user?.organizationMemberships[0]?.organization.id,
        },
      )
      setFolderList(result.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const openActivityLogsModal = (logs: any) => {
    setActivityLogs(logs)
    setActivityLogsModalVisible(true)
  }
  const closeActivityLogsModal = () => {
    setActivityLogsModalVisible(false)
  }

  const openShareFeedbackModal = (page: string) => {
    setPageName(page)
    setShareFeedbackVisible(true)
  }
  const closeShareFeedbackModal = () => {
    setShareFeedbackVisible(false)
  }

  const contextValue = {
    folderList,
    selectedFiles,
    conceptModalVisible,
    selectedConceptId,
    fileManagerModalVisible,
    conceptManagerModalVisible,
    setting,
    conceptAddFolderVisible,
    conceptAddFolderSelection,
    conceptFolders,
    socket,
    socketId,
    chatTopicId,
    setChatTopicId,
    pageName,
    retriever,
    setRetriever,
    subRetriever,
    setSubRetriever,
    selectedUploadFiles,
    activityLogs,
    activityLogsModalVisible,
    shareFeedbackVisible,
    openSettingsModal,
    setFolderList,
    setSelectedFiles,
    toggleSelectFolder,
    toggleSelectFile,
    isSelectedFolder,
    isSelectedFile,
    getSettingData,
    getFilePath,
    openFileManagerModal,
    closeFileManagerModal,
    openConceptManagerModal,
    closeConceptManagerModal,
    openConceptAddFolderModal,
    closeConceptAddFolderModal,
    getConceptFolders,
    setSocketInterface,
    fetchFolders,
    setSelectedUploadFiles,
    openActivityLogsModal,
    closeActivityLogsModal,
    openShareFeedbackModal,
    closeShareFeedbackModal,
    handleSettingModalOpen,
    handleCloseSettingsModal,
  }

  return (
    <RagContext.Provider value={contextValue}>{children}</RagContext.Provider>
  )
}

export const useRagContext = () => {
  const context = useContext(RagContext)
  if (!context) {
    throw new Error('useUserContext must be used within a RagContextProvider')
  }
  return context
}
