import React from 'react'

const RefreshIcon: React.FC<any> = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      stroke="#101010"
      fill="none"
      {...props}
    >
      <path
        d="M7.66671 2.99097C10.7963 2.99097 13.3334 5.52802 13.3334 8.65763C13.3334 11.7872 10.7963 14.3243 7.66671 14.3243C4.53709 14.3243 2.00004 11.7872 2.00004 8.65763C2.00004 7.41365 2.40089 6.26328 3.08047 5.32866"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8.67126 4.80212L7.03491 2.76433L9.51506 1.94024"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default RefreshIcon
