import React from 'react'

const OverviewIcon: React.FC<any> = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="black"
      {...props}
    >
      <g clipPath="url(#clip0_1196_27928)">
        <path
          d="M18.9999 12.7061C18.9818 12.7477 18.9651 12.7899 18.9451 12.8307C18.8932 12.9409 18.8059 13.0306 18.6971 13.0854C18.5883 13.1402 18.4643 13.157 18.3448 13.133C18.2254 13.1091 18.1174 13.0457 18.0382 12.9532C17.9589 12.8607 17.913 12.7442 17.9077 12.6225C17.9008 12.3995 17.9055 12.176 17.9055 11.9527V7.29036C17.9055 6.53923 17.4573 6.08834 16.7106 6.08834H7.27928C6.54842 6.08834 6.09478 6.54225 6.09478 7.27338V16.7211C6.09478 17.444 6.55034 17.9018 7.26996 17.9018C9.10606 17.9018 10.9423 17.9018 12.7788 17.9018C13.2094 17.9018 13.4814 18.2853 13.3231 18.6625C13.2478 18.8414 13.1004 18.931 12.93 18.9953H6.94234C6.91799 18.9849 6.89278 18.9767 6.86701 18.9707C6.35037 18.8947 5.87812 18.6359 5.53601 18.2414C5.19391 17.8469 5.00465 17.3428 5.0026 16.8206C4.99913 13.6038 4.99913 10.3871 5.0026 7.17038C5.00311 6.59368 5.23243 6.04074 5.64022 5.63295C6.04801 5.22516 6.60095 4.99585 7.17765 4.99534C10.3936 4.99114 13.6096 4.99114 16.8256 4.99534C17.8148 4.99534 18.6679 5.66319 18.9243 6.62005C18.9517 6.72469 18.9747 6.83043 18.9999 6.93562V12.7061Z"
          fill="inherit"
        />
        <path
          d="M18.3434 18.998C18.2147 18.9084 18.0731 18.8336 17.9599 18.7265C17.7035 18.4874 17.4595 18.2353 17.2146 17.9841C17.1598 17.9294 17.1201 17.9241 17.0502 17.9598C15.5384 18.7399 13.7904 17.9011 13.4617 16.2392C13.1943 14.8914 14.1868 13.5231 15.5482 13.3629C16.8845 13.2048 18.0429 14.0414 18.3013 15.3557C18.407 15.8926 18.3303 16.5337 17.9208 17.1837C18.0183 17.2692 18.1268 17.3574 18.2273 17.4541C18.3977 17.6184 18.5705 17.7828 18.7278 17.9581C18.8316 18.0745 18.9097 18.2137 18.9995 18.3416V18.5608C18.9695 18.6649 18.9136 18.7598 18.8369 18.8365C18.7603 18.9131 18.6654 18.9691 18.5612 18.9991L18.3434 18.998ZM15.8802 17.1653C16.1507 17.1659 16.4153 17.0863 16.6405 16.9364C16.8657 16.7865 17.0414 16.5732 17.1453 16.3235C17.2492 16.0737 17.2767 15.7988 17.2242 15.5334C17.1718 15.268 17.0418 15.0242 16.8507 14.8327C16.6596 14.6413 16.416 14.5108 16.1507 14.4579C15.8854 14.405 15.6104 14.4319 15.3605 14.5354C15.1105 14.6389 14.8969 14.8141 14.7466 15.0391C14.5964 15.264 14.5162 15.5285 14.5163 15.799C14.5176 16.1605 14.6616 16.5069 14.9171 16.7628C15.1725 17.0187 15.5187 17.1634 15.8802 17.1653Z"
          fill="inherit"
        />
        <path
          d="M13.3693 9.17924C12.5907 9.17924 11.812 9.18143 11.0334 9.17924C10.6359 9.1776 10.3721 8.80943 10.5023 8.44702C10.5809 8.22787 10.7825 8.08816 11.0304 8.08679C11.4221 8.08487 11.8136 8.08679 12.2053 8.08679C13.3663 8.08679 14.5274 8.08679 15.6887 8.08679C16.0919 8.08679 16.3582 8.43934 16.2365 8.80587C16.1607 9.03597 15.9549 9.17924 15.6909 9.17979C14.918 9.18034 14.1441 9.18016 13.3693 9.17924Z"
          fill="inherit"
        />
        <path
          d="M13.3687 12.4602C12.5899 12.4602 11.8114 12.4621 11.0328 12.4602C10.6356 12.4602 10.3718 12.0896 10.5025 11.7274C10.5811 11.5083 10.783 11.3691 11.0312 11.368C11.4547 11.3661 11.8779 11.368 12.3014 11.368C13.431 11.368 14.5602 11.368 15.6892 11.368C16.0922 11.368 16.3584 11.7214 16.2371 12.0877C16.1606 12.3175 15.9549 12.4605 15.6908 12.4608C14.9169 12.4611 14.1428 12.4609 13.3687 12.4602Z"
          fill="inherit"
        />
        <path
          d="M11.3947 15.939C11.263 15.939 11.1309 15.9432 10.9992 15.939C10.8565 15.9351 10.7211 15.8754 10.622 15.7727C10.5228 15.6701 10.4678 15.5327 10.4688 15.39C10.4697 15.2473 10.5265 15.1107 10.627 15.0094C10.7274 14.908 10.8636 14.8501 11.0063 14.848C11.2701 14.8428 11.5339 14.8419 11.7977 14.848C11.9387 14.8526 12.0725 14.9116 12.1709 15.0127C12.2693 15.1138 12.3248 15.2491 12.3256 15.3902C12.3264 15.5313 12.2725 15.6672 12.1753 15.7695C12.078 15.8717 11.945 15.9323 11.804 15.9385C11.6687 15.9437 11.5317 15.939 11.3947 15.939Z"
          fill="inherit"
        />
        <path
          d="M8.54877 8.08752C8.64876 8.08752 8.74902 8.08396 8.8501 8.08752C8.99112 8.0932 9.12446 8.15327 9.22215 8.25513C9.31983 8.35699 9.37428 8.49272 9.37407 8.63385C9.37385 8.77498 9.319 8.91055 9.22101 9.01211C9.12301 9.11368 8.98949 9.17334 8.84846 9.1786C8.65314 9.1849 8.45728 9.1849 8.26196 9.1786C8.12031 9.1739 7.98604 9.11427 7.88757 9.01233C7.7891 8.91039 7.73416 8.77414 7.73438 8.6324C7.73459 8.49067 7.78994 8.35458 7.88871 8.25294C7.98749 8.15129 8.12194 8.09207 8.26361 8.08779C8.35784 8.08423 8.45344 8.08752 8.54877 8.08752Z"
          fill="inherit"
        />
        <path
          d="M8.54988 11.3688C8.64987 11.3688 8.75013 11.3655 8.85121 11.3688C8.99597 11.3692 9.13464 11.4271 9.23672 11.5297C9.3388 11.6324 9.39592 11.7714 9.39552 11.9161C9.39512 12.0609 9.33723 12.1995 9.23459 12.3016C9.13195 12.4037 8.99296 12.4608 8.8482 12.4604C8.65288 12.4667 8.45702 12.467 8.2617 12.4604C8.12005 12.4557 7.98581 12.396 7.88739 12.294C7.78897 12.192 7.73409 12.0557 7.73438 11.914C7.73466 11.7722 7.79008 11.6362 7.88891 11.5346C7.98774 11.433 8.12222 11.3738 8.2639 11.3696C8.35868 11.3647 8.45428 11.3688 8.54988 11.3688Z"
          fill="inherit"
        />
        <path
          d="M8.55813 15.9391C8.45815 15.9391 8.35789 15.9426 8.2568 15.9391C8.11514 15.9341 7.98099 15.8741 7.88275 15.7719C7.78452 15.6697 7.7299 15.5333 7.73047 15.3916C7.73104 15.2498 7.78675 15.1138 7.88581 15.0124C7.98486 14.911 8.11949 14.8522 8.26119 14.8483C8.4565 14.8425 8.65237 14.842 8.84768 14.8483C8.98871 14.8543 9.12192 14.9147 9.21937 15.0168C9.31682 15.1189 9.37094 15.2548 9.37038 15.3959C9.36981 15.5371 9.3146 15.6725 9.21633 15.7738C9.11806 15.8752 8.98437 15.9345 8.8433 15.9394C8.74934 15.9437 8.65456 15.9391 8.55813 15.9391Z"
          fill="inherit"
        />
      </g>
    </svg>

  )
}

export default OverviewIcon
