import React, { useEffect, useMemo, useState } from 'react'
import ModalHeader from '../Header/ModaHeader'
import { useRagContext } from '../../../contexts/RagContext'
import BreadCrumb, { BreadCrumbMenu } from '../../common/BreadCrumb'
import editPencilIcon from '../../../assets/svg/edit-pen.svg'
import ICPIcon from '../../icons/ICPIcon'
import logo from '../../../assets/auth/logo.svg'
import { useHistory } from 'react-router'
import PieChartIcon from '../../icons/PieChartIcon'
import { IoIosInformationCircleOutline } from 'react-icons/io'
import axios from 'axios'
import { useUserContext } from '../../../contexts/UserContext'
import { GrLinkNext } from 'react-icons/gr'
import DiamondValueIcon from '../../icons/DiamondValueIcon'
import PuzzleIcon from '../../icons/PuzzleIcon'
import LightOnIcon from '../../icons/LightOnIcon'
import SourceMaterialDrawer from './SourceMaterialDrawer/SourceMaterialDrawer'
import { BiLoaderAlt } from 'react-icons/bi'
import BrainStormEditModal from './BrainStormEditModal/BrainStormEditModal'
import RefreshIcon from '../../icons/RefreshIcon'
import FavoriteIcon from '../../icons/FavoriteIcon'

const BrainStormModal: React.FC = () => {
  const history = useHistory()
  const {
    brainStormModalVisible,
    selectedBrainstormId,
    selectedBrainstormConceptId,
    socket,
    socketId,
    openShareFeedbackModal,
    getConceptFolders,
  } = useRagContext()
  const { user } = useUserContext()

  const [brainstormData, setBrainstormData] = useState<any>(null)
  const [selectedHMWQuestionIndex, setSelectedHMWQuestionIndex] = useState<
    number
  >(-1)
  const [
    selectedDraftConceptIndexes,
    setSelectedDraftConceptIndexes,
  ] = useState<number[]>([])
  const [brainstormConceptData, setBrainstormConceptData] = useState<any>(null)
  const [visible, setVisible] = useState<boolean>(false)
  const [showLoading, setShowLoading] = useState<boolean>(false)
  const [editModalOpen, setEditModalOpen] = React.useState<
    boolean
  >(false)
  const [likeIndexes, setLikeIndexes] = useState<number[]>([]);
  const [unlikeIndexes, setUnlikeIndexes] = useState<number[]>([]);
  const [regeneratingConcepts, setRegeneratingConcepts] = useState<string[]>([])

  const menus: BreadCrumbMenu[] = useMemo(() => {
    return [
      {
        title: 'Home',
        active: false,
        onClick: () => {
          closeConceptModal()
        },
      },
      {
        title: brainstormData?.name,
        active: true,
        onClick: () => { },
      },
    ]
  }, [brainstormData])

  const brainstormStatus = useMemo(() => {
    if (brainstormData) {
      if (brainstormData.draft_concepts.length === 0) {
        return {
          fromSource: brainstormData.fromSource,
          status: 'hmw_question',
        }
      } else if (brainstormData.generated_concepts.length === 0) {
        return {
          fromSource: brainstormData.fromSource,
          status: 'draft_concepts',
        }
      } else if (
        selectedBrainstormConceptId &&
        selectedBrainstormConceptId !== ''
      ) {
        return {
          fromSource: brainstormData.fromSource,
          status: 'concept_detail',
        }
      } else {
        return {
          fromSource: brainstormData.fromSource,
          status: 'generated_concepts',
        }
      }
    } else {
      return {
        fromSource: false,
        status: 'fetching',
      }
    }
  }, [brainstormData, selectedBrainstormConceptId])

  const selectedGeneratedConcept = useMemo(() => {
    if (
      brainstormData &&
      selectedBrainstormConceptId &&
      selectedBrainstormConceptId !== ''
    ) {
      return brainstormData.generated_concepts.find(
        (e: any) => e.id === selectedBrainstormConceptId,
      )
    } else {
      return null
    }
  }, [brainstormData, selectedBrainstormConceptId])

  const closeConceptModal = () => {
    history.push({
      pathname: '/',
    })
  }

  const getBrainstormDetail = async () => {
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/getBrainStormConcepts',
        {
          id: selectedBrainstormId,
        },
      )

      setBrainstormData(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const generateMoreHMWQuestions = async () => {
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/generateMoreHMWQuestions',
        {
          brainstorm_id: selectedBrainstormId,
          team_id: user?.organizationMemberships[0]?.organization.id,
        },
      )

      setBrainstormData((prev: any) => {
        return {
          ...prev,
          hmw_questions: response.data.response,
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const regenerateHMWQuestions = async () => {
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/regenerateHMWQuestions',
        {
          brainstorm_id: selectedBrainstormId,
          team_id: user?.organizationMemberships[0]?.organization.id,
        },
      )

      setBrainstormData((prev: any) => {
        return {
          ...prev,
          hmw_questions: response.data.response,
        }
      })
      setSelectedHMWQuestionIndex(-1)
    } catch (error) {
      console.log(error)
    }
  }

  const createBrainStormConcepts = async () => {
    try {
      setShowLoading(true)
      let response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/createBrainStormConcepts',
        {
          selected_hmw_question:
            brainstormData.hmw_questions[selectedHMWQuestionIndex],
          selected_hmw_question_index: selectedHMWQuestionIndex,
          brainstorm_id: selectedBrainstormId,
          team_id: user?.organizationMemberships[0]?.organization.id,
        },
      )

      setBrainstormData((prev: any) => {
        return {
          ...prev,
          draft_concepts: response.data.response,
          selected_hmw_question_index: selectedHMWQuestionIndex,
        }
      })
      setShowLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const toggleDraftConcept = (index: number) => {
    if (selectedDraftConceptIndexes.findIndex((e) => e === index) !== -1) {
      setSelectedDraftConceptIndexes((prev) => {
        return prev.filter((e) => e !== index)
      })
    } else {
      setSelectedDraftConceptIndexes((prev) => {
        return [...prev, index]
      })
    }
  }

  const generateMoreBrainStormConcepts = async () => {
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/generateMoreBrainStormConcepts',
        {
          brainstorm_id: selectedBrainstormId,
          team_id: user?.organizationMemberships[0]?.organization.id,
        },
      )

      setBrainstormData((prev: any) => {
        return {
          ...prev,
          draft_concepts: response.data.response,
        }
      })
      setSelectedDraftConceptIndexes([])
    } catch (error) {
      console.log(error)
    }
  }

  const regenerateBrainStormConcepts = async () => {
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/regenerateBrainStormConcepts',
        {
          brainstorm_id: selectedBrainstormId,
          team_id: user?.organizationMemberships[0]?.organization.id,
        },
      )

      setBrainstormData((prev: any) => {
        return {
          ...prev,
          draft_concepts: response.data.response,
        }
      })
      setSelectedDraftConceptIndexes([])
    } catch (error) {
      console.log(error)
    }
  }

  const saveBrainStormConcepts = async () => {
    try {
      setShowLoading(true)
      let response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/saveBrainStormConcepts',
        {
          brainstorm_id: selectedBrainstormId,
          team_id: user?.organizationMemberships[0]?.organization.id,
          concepts_array: selectedDraftConceptIndexes.map((e) => {
            return brainstormData.draft_concepts[e]
          }),
        },
      )

      let concept_folder_id = response.data.concept_folder_id;
      for (let index = 0; index < selectedDraftConceptIndexes.length; index++) {
        const conceptArray = brainstormData.draft_concepts[index];
        let params: any = {
          team_id: user?.organizationMemberships[0]?.organization.id,
          concept_folder_id: concept_folder_id,
          socketId: socketId,
          context: conceptArray.join(': '),
          type: 'brainstorm',
          brainstorm_id: selectedBrainstormId,
          concept_array: conceptArray,
          pinecone_id: ''
        }

        try {
          await axios.post(
            process.env.REACT_APP_API_URL + 'api/createConcept',
            params,
          )
        } catch (error) {
          console.log(error)
        }
      }

      getBrainstormDetail()
      getConceptFolders()
      setShowLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleEditConcept = (id: string) => {
    const searchParams = new URLSearchParams()
    searchParams.set('render_type', 'modal')
    searchParams.set('render_modal', 'concept_modal')
    searchParams.set('concept_id', id)

    history.push({
      pathname: '/',
      search: `?${searchParams.toString()}`,
    })
  }

  const handleShowGeneratedConcepts = () => {
    const searchParams = new URLSearchParams()
    searchParams.set('render_type', 'modal')
    searchParams.set('render_modal', 'brainstorm_modal')
    searchParams.set('brainstorm_id', selectedBrainstormId || '')

    history.push({
      pathname: '/',
      search: `?${searchParams.toString()}`,
    })
  }

  const saveBrainStormConceptDetails = async () => {
    try {
      setShowLoading(true)
      await axios.post(
        process.env.REACT_APP_API_URL + 'api/saveBrainStormConceptDetails',
        {
          concept_id: brainstormConceptData.id,
          concept_title: brainstormConceptData.concept_title,
          concept_description: brainstormConceptData.concept_description,
          problem: brainstormConceptData.problem,
          need: brainstormConceptData.need,
          solution: brainstormConceptData.solution,
          value: brainstormConceptData.value,
        },
      )

      handleShowGeneratedConcepts()
      getBrainstormDetail()
      getConceptFolders()
      setShowLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const generateBrainStormConceptDetails = async (type: string) => {
    try {
      setRegeneratingConcepts((prev) => ([...prev, type]))
      let response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/generateBrainStormConceptDetails',
        {
          brainstorm_id: selectedBrainstormId,
          team_id: user?.organizationMemberships[0]?.organization.id,
          concept: {
            concept_title: brainstormConceptData.concept_title,
            concept_description: brainstormConceptData.concept_description,
          },
          type,
        },
      )
      setRegeneratingConcepts((prev) => prev.filter((e) => e !== type))

      setBrainstormConceptData((prev: any) => ({
        ...prev,
        [type]: response.data.response,
      }))
    } catch (error) {
      console.log(error)
    }
  }

  const regenerateHMWQuestion = async (question: string, index: number) => {
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/regenerateHMWQuestion',
        {
          brainstorm_id: selectedBrainstormId,
          team_id: user?.organizationMemberships[0]?.organization.id,
          selected_hmw_question: question,
          selected_hmw_question_index: index
        },
      )

      setBrainstormData((prev: any) => {
        return {
          ...prev,
          hmw_questions: prev.hmw_questions.map((q: string, idx: number) => idx === index ? response.data.response : q),
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const regenerateBrainStormConcept = async (concept: string[], index: number) => {
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/regenerateBrainStormConcept',
        {
          brainstorm_id: selectedBrainstormId,
          team_id: user?.organizationMemberships[0]?.organization.id,
          concept: concept,
          selected_brainstorm_concept_index: index
        },
      )

      setBrainstormData((prev: any) => {
        return {
          ...prev,
          draft_concepts: prev.draft_concepts.map((q: string, idx: number) => idx === index ? response.data.response : q),
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const toggleLike = (index: number) => {
    if (likeIndexes.findIndex((e) => e === index) !== -1) {
      setLikeIndexes((prev) => prev.filter((e) => e !== index))
    } else {
      setLikeIndexes((prev) => ([...prev, index]))
    }
  }

  const toggleUnlike = (index: number) => {
    if (unlikeIndexes.findIndex((e) => e === index) !== -1) {
      setUnlikeIndexes((prev) => prev.filter((e) => e !== index))
    } else {
      setUnlikeIndexes((prev) => ([...prev, index]))
    }
  }

  const isLiked = (index: number) => {
    return likeIndexes.findIndex((e) => e === index) !== -1
  }
  const isUnliked = (index: number) => {
    return unlikeIndexes.findIndex((e) => e === index) !== -1
  }

  useEffect(() => {
    if (socket) {
      socket.on('hmw_questions', (data: any) => {
        if (data.socketId === socketId) {
          setBrainstormData((prev: any) => ({ ...prev, hmw_questions: data.hmw_questions }))
        }
      })
    }

    return () => {
      if (socket) {
        return socket.off('hmw_questions')
      }
    }
  }, [socket, socketId])

  useEffect(() => {
    getBrainstormDetail()
    if (!brainStormModalVisible) {
      setSelectedHMWQuestionIndex(-1)
      setSelectedDraftConceptIndexes([])
      setBrainstormConceptData(null)
      setVisible(false)
    }
  }, [selectedBrainstormId, brainStormModalVisible])

  useEffect(() => {
    if (selectedGeneratedConcept) {
      setBrainstormConceptData(selectedGeneratedConcept)
    } else {
      setBrainstormConceptData(selectedGeneratedConcept)
    }
  }, [selectedGeneratedConcept])

  useEffect(() => {
    setLikeIndexes([]);
    setUnlikeIndexes([]);
  }, [brainstormStatus])

  return (
    <div
      className="w-screen h-screen fixed top-0 left-0 z-20 bg-[#F4F4F5]"
      hidden={!brainStormModalVisible}
    >
      <ModalHeader></ModalHeader>
      <BreadCrumb menus={menus} />
      {brainstormData && (
        <>
          <div className="px-10 pt-4 pb-6 flex justify-between border-t border-solid border-[#D4D4D8]">
            <div className="">
              <div className="flex gap-2 items-center">
                <div className="text-xl font-bold text-[#27272A]">
                  {brainstormData.name}
                </div>
                <button className="outline-none flex items-center justify-center w-8 h-8 border border-solid border-[#D4D4D8] rounded-full" onClick={() => setEditModalOpen(true)}>
                  <img src={editPencilIcon} alt="" className="w-5 h-5" />
                </button>
              </div>
              <div className="text-base font-normal text-[#71717A]">
                {brainstormData.description}
              </div>
            </div>
            {brainstormData.fromSource && (
              <button
                className="py-[6px] px-2 border border-solid border-[#D4D4D8] rounded-full h-9 flex items-center gap-2"
                onClick={() => setVisible(true)}
              >
                <span className="text-sm font-bold text-[#71717A]">
                  Source Material
                </span>
                <IoIosInformationCircleOutline className="flex-none w-6 h-6 text-[#71717A]" />
              </button>
            )}
          </div>
          <div className="px-32 flex gap-6">
            <div className="flex flex-col w-[336px] gap-4 h-[calc(100vh-241px)] overflow-auto">
              <div className="bg-white flex-none rounded-2xl overflow-hidden w-full border border-solid border-[#D4D4D8] h-[calc(100vh-305px)]">
                <div className="flex flex-col gap-4 p-4">
                  <div className="text-xl font-bold text-[rgb(39,39,42)]">
                    New Ideation
                  </div>
                  <div className="flex flex-col gap-4">
                    <div
                      className={`p-4 flex gap-2 cursor-pointer ${brainstormStatus.status === 'hmw_question' ||
                        brainstormStatus.status === 'draft_concepts'
                        ? 'stroke-[#3B82F6] fill-[#3B82F6]'
                        : 'stroke-[#09090B] fill-[#09090B]'
                        }`}
                    >
                      <ICPIcon
                        className="flex-none w-6 h-6"
                        stroke={'inherit'}
                      />
                      <div
                        className={`text-base font-medium whitespace-nowrap overflow-hidden text-ellipsis flex-1 ${brainstormStatus.status === 'hmw_question' ||
                          brainstormStatus.status === 'draft_concepts'
                          ? 'text-[#3B82F6]'
                          : 'text-[#71717A]'
                          }`}
                      >
                        Problem-framing
                      </div>
                    </div>
                    <div
                      className={`p-4 flex gap-2 cursor-pointer ${brainstormStatus.status === 'generated_concepts' ||
                        brainstormStatus.status === 'concept_detail'
                        ? 'stroke-[#3B82F6] fill-[#3B82F6]'
                        : 'stroke-[#09090B] fill-[#09090B]'
                        }`}
                      onClick={() => {
                        if (brainstormStatus.status === 'concept_detail') {
                          handleShowGeneratedConcepts()
                        }
                      }}
                    >
                      <PieChartIcon
                        className="flex-none w-6 h-6"
                        stroke={'inherit'}
                      />
                      <div
                        className={`text-base font-medium whitespace-nowrap overflow-hidden text-ellipsis flex-1 ${brainstormStatus.status === 'generated_concepts' ||
                          brainstormStatus.status === 'concept_detail'
                          ? 'text-[#3B82F6]'
                          : 'text-[#71717A]'
                          }`}
                      >
                        Concepts Generated
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4">
                <button
                  className="w-full h-12 rounded-xl border border-solid border-[#3B82F6] outline-none flex items-center justify-center gap-2"
                  onClick={() => openShareFeedbackModal('Brainstorm')}
                >
                  <span className="text-[#3B82F6] text-base font-bold font-Satoshi">
                    Share Feedback
                  </span>
                </button>
              </div>
            </div>
            <div className="flex-1 p-5 bg-white h-[calc(100vh-241px)] overflow-auto">
              {brainstormStatus.status === 'hmw_question' && (
                <div className="">
                  <div className="flex flex-col gap-6">
                    <div className="w-fit ml-auto bg-[#EDEDED] rounded-lg px-4 py-3 text-base text-[#18181B] font-normal">
                      Please generate a list of 5 "how might we" questions from this reference.
                    </div>
                    {brainstormData.hmw_questions.length > 0 ?
                      <>
                        <div className="flex gap-[10px]">
                          <img
                            src={logo}
                            alt="Logo"
                            className="flex-none w-5 h-5 mt-1"
                          />
                          <div className="flex-1 text-[#000000cb] text-base font-medium">
                            {`Based on the provided context, here are ${brainstormData.hmw_questions.length > 5 ? 'ten' : 'five'} "How Might We" (HMW) questions designed to stimulate innovation and improve processes:`}
                          </div>
                        </div>
                        <div className="flex flex-col gap-4 relative">
                          {brainstormData.hmw_questions.map(
                            (question: string, index: number) => (
                              <div className='relative group'>
                                <div
                                  className="flex gap-[10px] cursor-pointer"
                                  key={index}
                                  onClick={() => setSelectedHMWQuestionIndex(index)}
                                >
                                  <input
                                    type="radio"
                                    className="w-5 h-5 flex-none mt-[2px]"
                                    checked={selectedHMWQuestionIndex === index}
                                    readOnly
                                  />
                                  <div className="flex-1 text-[#000000cb] text-base font-normal">
                                    {question}
                                  </div>
                                </div>
                                <div className="absolute right-0 top-0 hidden group-hover:flex px-2 gap-[10px] bg-white">
                                  <RefreshIcon stroke="#3B82F6" strokeWidth="1" className="w-6 h-6 flex-none cursor-pointer" onClick={() => regenerateHMWQuestion(question, index)} />
                                  <FavoriteIcon className="w-6 h-6 flex-none cursor-pointer" fill={isLiked(index) ? '#7cfc00' : '#3B82F6'} onClick={() => toggleLike(index)} />
                                  <FavoriteIcon className="w-6 h-6 flex-none cursor-pointer rotate-180" fill={isUnliked(index) ? '#7cfc00' : '#3B82F6'} onClick={() => toggleUnlike(index)} />
                                </div>
                              </div>
                            ),
                          )}
                        </div>
                        <div className="flex gap-2 pl-[34px]">
                          <button
                            className={`py-2 px-6 h-10 rounded-full border border-solid ${brainstormData.hmw_questions.length > 5 ? 'bg-[#E4E4E7]' : 'border-[#71717A]'}`}
                            onClick={() => generateMoreHMWQuestions()}
                            disabled={brainstormData.hmw_questions.length > 5}
                          >
                            <div className={`text-base font-medium ${brainstormData.hmw_questions.length > 5 ? 'text-[#A1A1AA]' : 'text-[#71717A]'}`}>
                              Generate More
                            </div>
                          </button>
                          <button
                            className="py-2 px-6 h-10 rounded-full border border-solid border-[#71717A]"
                            onClick={() => regenerateHMWQuestions()}
                          >
                            <div className="text-base font-medium text-[#71717A]">
                              Regenerate with human impact in mind.
                            </div>
                          </button>
                        </div>
                        <div className="flex items-center justify-between pl-[34px]">
                          <div className="text-base font-medium text-[#A1A1AA]">
                            *Select one to brainstorm
                          </div>
                          <button
                            className={`py-3 px-6 h-12 rounded-full flex items-center gap-2 ${selectedHMWQuestionIndex === -1 || showLoading
                              ? 'bg-[#E4E4E7]'
                              : 'bg-[#3B82F6]'
                              }`}
                            disabled={selectedHMWQuestionIndex === -1 || showLoading}
                            onClick={() => createBrainStormConcepts()}
                          >
                            <div
                              className={`text-base font-bold ${selectedHMWQuestionIndex === -1
                                ? 'text-[#A1A1AA]'
                                : 'text-white'
                                }`}
                            >
                              Next To Brainstorm
                            </div>
                            {showLoading &&
                              <BiLoaderAlt className="w-6 h-6 text-white animate-spin" />
                            }
                          </button>
                        </div>
                      </>
                      : <div className="w-full flex pl-[30px]">
                        <BiLoaderAlt className="w-6 h-6 text-[#3B82F6] animate-spin" />
                      </div>
                    }
                  </div>
                </div>
              )}
              {brainstormStatus.status === 'draft_concepts' && (
                <div className="">
                  <div className="flex flex-col gap-6">
                    <div className="w-fit ml-auto bg-[#EDEDED] rounded-lg px-4 py-3 text-base text-[#18181B] font-normal">
                      {
                        brainstormData.hmw_questions[
                        brainstormData.selected_hmw_question_index
                        ]
                      }
                    </div>
                    <div className="flex flex-col gap-4">
                      {brainstormData.draft_concepts.map(
                        (draftConcept: string[], index: number) => (
                          <div className='relative group'>
                            <div
                              className="flex gap-[10px] cursor-pointer"
                              key={index}
                              onClick={() => toggleDraftConcept(index)}
                            >
                              <input
                                type="checkbox"
                                className="w-5 h-5 flex-none mt-[2px]"
                                checked={
                                  selectedDraftConceptIndexes.findIndex(
                                    (e) => e === index,
                                  ) !== -1
                                }
                                readOnly
                              />
                              <div className="flex-1 text-[#000000cb] text-base font-normal">
                                <span className="font-bold">
                                  {draftConcept[0]}
                                  {`: `}
                                </span>
                                <span className="font-normal">
                                  {draftConcept[1]}
                                </span>
                              </div>
                            </div>
                            <div className="absolute right-0 top-0 hidden group-hover:flex px-2 gap-[10px] bg-white">
                              <RefreshIcon stroke="#3B82F6" strokeWidth="1" className="w-6 h-6 flex-none cursor-pointer" onClick={() => regenerateBrainStormConcept(draftConcept, index)} />
                              <FavoriteIcon className="w-6 h-6 flex-none cursor-pointer" fill={isLiked(index) ? '#7cfc00' : '#3B82F6'} onClick={() => toggleLike(index)} />
                              <FavoriteIcon className="w-6 h-6 flex-none cursor-pointer rotate-180" fill={isUnliked(index) ? '#7cfc00' : '#3B82F6'} onClick={() => toggleUnlike(index)} />
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                    <div className="flex gap-2 pl-[34px]">
                      <button
                        className="py-2 px-6 h-10 rounded-full border border-solid border-[#71717A]"
                        onClick={() => generateMoreBrainStormConcepts()}
                      >
                        <div className="text-base font-medium text-[#71717A]">
                          Add more detail
                        </div>
                      </button>
                      <button
                        className="py-2 px-6 h-10 rounded-full border border-solid border-[#71717A]"
                        onClick={() => regenerateBrainStormConcepts()}
                      >
                        <div className="text-base font-medium text-[#71717A]">
                          Regenerate with focused on sustainability
                        </div>
                      </button>
                    </div>
                    <div className="flex items-center justify-between pl-[34px]">
                      <div className="text-base font-medium text-[#A1A1AA]">
                        *Select responses to refine into new concepts
                      </div>
                      <button
                        className={`py-3 px-6 h-12 rounded-full flex items-center gap-2 ${selectedDraftConceptIndexes.length === 0 || showLoading
                          ? 'bg-[#E4E4E7]'
                          : 'bg-[#3B82F6]'
                          }`}
                        disabled={selectedDraftConceptIndexes.length === 0 || showLoading}
                        onClick={() => saveBrainStormConcepts()}
                      >
                        <div
                          className={`text-base font-bold ${selectedDraftConceptIndexes.length === 0 || showLoading
                            ? 'text-[#A1A1AA]'
                            : 'text-white'
                            }`}
                        >
                          Generate Concept
                        </div>
                        {showLoading &&
                          <BiLoaderAlt className="w-6 h-6 text-[#A1A1AA] animate-spin" />
                        }
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {brainstormStatus.status === 'generated_concepts' && (
                <div className="">
                  <div className="flex flex-col gap-6">
                    <div className="">
                      <div className="text-xl font-bold text-[#3B82F6]">
                        Hey {user?.unsafeMetadata?.user_name}!
                      </div>
                      <div className="text-2xl font-medium text-[#71717A]">
                        Here are some of your concepts
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      {brainstormData.generated_concepts.map(
                        (generatedConcept: any, index: number) => (
                          <div
                            className="border border-solid border-[#D4D4D8] rounded-lg overflow-hidden p-[14px]"
                            key={index}
                          >
                            <div className="flex flex-col gap-4 h-full">
                              <div className="w-12 h-12 flex items-center justify-center bg-[#F4F4F5] rounded-[4px]">
                                <span className="text-xl text-[#3B82F6] font-medium">
                                  #{index + 1}
                                </span>
                              </div>
                              <div className="flex-1">
                                <div className="text-lg font-bold text-[#18181B] mb-2">
                                  {generatedConcept.concept_title}
                                </div>
                                <div className="text-sm font-medium text-[#71717A]">
                                  {generatedConcept.concept_description}
                                </div>
                              </div>
                              <div className="flex justify-end">
                                <button
                                  className="p-0 bg-transparent border-none outline-none flex items-center gap-2"
                                  onClick={() =>
                                    handleEditConcept(generatedConcept.id)
                                  }
                                >
                                  <span className="text-sm font-bold text-[#3B82F6]">
                                    Edit Concept
                                  </span>
                                  <GrLinkNext className="text-sm font-bold text-[#3B82F6]" />
                                </button>
                              </div>
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </div>
              )}
              {brainstormStatus.status === 'concept_detail' &&
                selectedGeneratedConcept &&
                brainstormConceptData && (
                  <div className="">
                    <div className="flex flex-col gap-4">
                      <div className="">
                        <label
                          htmlFor="brainstorm_concept_title"
                          className="ml-1 mb-1 text-base font-medium text-[#09090B]"
                        >
                          Concept
                        </label>
                        <input
                          type="text"
                          id="brainstorm_concept_title"
                          name="brainstorm_concept_title"
                          className="w-full h-12 rounded-lg outline-none bg-[#FCFCFC] border border-solid border-[#D4D4D8] px-4 py-3 text-sm font-medium text-[#3F3F46]"
                          value={brainstormConceptData.concept_title}
                          onChange={(e) =>
                            setBrainstormConceptData((prev: any) => ({
                              ...prev,
                              concept_title: e.target.value,
                            }))
                          }
                        />
                      </div>
                      <div className="">
                        <label
                          htmlFor="brainstorm_concept_description"
                          className="ml-1 mb-1 text-base font-medium text-[#09090B]"
                        >
                          Description
                        </label>
                        <textarea
                          id="brainstorm_concept_description"
                          name="brainstorm_concept_description"
                          className="w-full h-24 rounded-lg outline-none bg-[#FCFCFC] border border-solid border-[#D4D4D8] px-4 py-3 text-sm font-medium text-[#3F3F46]"
                          value={brainstormConceptData.concept_description}
                          onChange={(
                            e: React.ChangeEvent<HTMLTextAreaElement>,
                          ) =>
                            setBrainstormConceptData((prev: any) => ({
                              ...prev,
                              concept_description: e.target.value,
                            }))
                          }
                        />
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        <div className="border border-solid border-[#D4D4D8] rounded-lg overflow-hidden p-[14px]">
                          <div className="flex flex-col gap-4 h-full">
                            <div className="w-12 h-12 flex items-center justify-center bg-[#F4F4F5] rounded-[4px]">
                              <span className="text-xl text-[#3B82F6] font-medium">
                                <DiamondValueIcon className="w-[20px] h-[18px]" />
                              </span>
                            </div>
                            <div className="">
                              <div className="text-lg font-bold text-[#18181B] mb-2">
                                Problem
                              </div>
                              <textarea
                                className={`text-sm font-medium text-[#71717A] w-full border-none outline-none resize-none ${brainstormConceptData.problem !== '' ? 'h-[120px]' : 'h-[84px]'}`}
                                value={brainstormConceptData.problem}
                                onChange={(
                                  e: React.ChangeEvent<HTMLTextAreaElement>,
                                ) =>
                                  setBrainstormConceptData((prev: any) => ({
                                    ...prev,
                                    problem: e.target.value,
                                  }))
                                }
                              ></textarea>
                            </div>
                            {brainstormConceptData.problem === '' && (
                              <div className="flex justify-end">
                                <button
                                  className="p-0 bg-transparent border-none outline-none flex items-center gap-2"
                                  onClick={() =>
                                    generateBrainStormConceptDetails('problem')
                                  }
                                  disabled={Boolean(regeneratingConcepts.find((e) => e === 'problem'))}
                                >
                                  {regeneratingConcepts.find((e) => e === 'problem') ?
                                    <BiLoaderAlt className="w-[14px] h-[14px] text-[#3B82F6] animate-spin" />
                                    :
                                    <span className="text-sm font-bold text-[#3B82F6]">
                                      Suggest fill
                                    </span>
                                  }
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="border border-solid border-[#D4D4D8] rounded-lg overflow-hidden p-[14px]">
                          <div className="flex flex-col gap-4 h-full">
                            <div className="w-12 h-12 flex items-center justify-center bg-[#F4F4F5] rounded-[4px]">
                              <span className="text-xl text-[#3B82F6] font-medium">
                                <PuzzleIcon className="w-[26px] h-[26px]" />
                              </span>
                            </div>
                            <div className="">
                              <div className="text-lg font-bold text-[#18181B] mb-2">
                                Needs
                              </div>
                              <textarea
                                className={`text-sm font-medium text-[#71717A] w-full border-none outline-none resize-none ${brainstormConceptData.need !== '' ? 'h-[120px]' : 'h-[84px]'}`}
                                value={brainstormConceptData.need}
                                onChange={(
                                  e: React.ChangeEvent<HTMLTextAreaElement>,
                                ) =>
                                  setBrainstormConceptData((prev: any) => ({
                                    ...prev,
                                    need: e.target.value,
                                  }))
                                }
                              ></textarea>
                            </div>
                            {brainstormConceptData.need === '' && (
                              <div className="flex justify-end">
                                <button
                                  className="p-0 bg-transparent border-none outline-none flex items-center gap-2"
                                  onClick={() =>
                                    generateBrainStormConceptDetails('need')
                                  }
                                  disabled={Boolean(regeneratingConcepts.find((e) => e === 'need'))}
                                >
                                  {regeneratingConcepts.find((e) => e === 'need') ?
                                    <BiLoaderAlt className="w-[14px] h-[14px] text-[#3B82F6] animate-spin" />
                                    :
                                    <span className="text-sm font-bold text-[#3B82F6]">
                                      Suggest fill
                                    </span>
                                  }
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="border border-solid border-[#D4D4D8] rounded-lg overflow-hidden p-[14px]">
                          <div className="flex flex-col gap-4 h-full">
                            <div className="w-12 h-12 flex items-center justify-center bg-[#F4F4F5] rounded-[4px]">
                              <span className="text-xl text-[#3B82F6] font-medium">
                                <LightOnIcon className="w-[26px] h-[26px]" />
                              </span>
                            </div>
                            <div className="">
                              <div className="text-lg font-bold text-[#18181B] mb-2">
                                Solutions
                              </div>
                              <textarea
                                className={`text-sm font-medium text-[#71717A] w-full border-none outline-none resize-none ${brainstormConceptData.solution !== '' ? 'h-[120px]' : 'h-[84px]'}`}
                                value={brainstormConceptData.solution}
                                onChange={(
                                  e: React.ChangeEvent<HTMLTextAreaElement>,
                                ) =>
                                  setBrainstormConceptData((prev: any) => ({
                                    ...prev,
                                    solution: e.target.value,
                                  }))
                                }
                              ></textarea>
                            </div>
                            {brainstormConceptData.solution === '' && (
                              <div className="flex justify-end">
                                <button
                                  className="p-0 bg-transparent border-none outline-none flex items-center gap-2"
                                  onClick={() =>
                                    generateBrainStormConceptDetails('solution')
                                  }
                                  disabled={Boolean(regeneratingConcepts.find((e) => e === 'solution'))}
                                >
                                  {regeneratingConcepts.find((e) => e === 'solution') ?
                                    <BiLoaderAlt className="w-[14px] h-[14px] text-[#3B82F6] animate-spin" />
                                    :
                                    <span className="text-sm font-bold text-[#3B82F6]">
                                      Suggest fill
                                    </span>
                                  }
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="border border-solid border-[#D4D4D8] rounded-lg overflow-hidden p-[14px]">
                          <div className="flex flex-col gap-4 h-full">
                            <div className="w-12 h-12 flex items-center justify-center bg-[#F4F4F5] rounded-[4px]">
                              <span className="text-xl text-[#3B82F6] font-medium">
                                <DiamondValueIcon className="w-6 h-5" />
                              </span>
                            </div>
                            <div className="">
                              <div className="text-lg font-bold text-[#18181B] mb-2">
                                Value
                              </div>
                              <textarea
                                className={`text-sm font-medium text-[#71717A] w-full border-none outline-none resize-none ${brainstormConceptData.value !== '' ? 'h-[120px]' : 'h-[84px]'}`}
                                value={brainstormConceptData.value}
                                onChange={(
                                  e: React.ChangeEvent<HTMLTextAreaElement>,
                                ) =>
                                  setBrainstormConceptData((prev: any) => ({
                                    ...prev,
                                    value: e.target.value,
                                  }))
                                }
                              ></textarea>
                            </div>
                            {brainstormConceptData.value === '' && (
                              <div className="flex justify-end">
                                <button
                                  className="p-0 bg-transparent border-none outline-none flex items-center gap-2"
                                  onClick={() =>
                                    generateBrainStormConceptDetails('value')
                                  }
                                  disabled={Boolean(regeneratingConcepts.find((e) => e === 'value'))}
                                >
                                  {regeneratingConcepts.find((e) => e === 'value') ?
                                    <BiLoaderAlt className="w-[14px] h-[14px] text-[#3B82F6] animate-spin" />
                                    :
                                    <span className="text-sm font-bold text-[#3B82F6]">
                                      Suggest fill
                                    </span>
                                  }
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-end gap-4">
                        <button
                          className={`w-[164px] h-12 rounded-lg border border-solid border-[#71717A] outline-none items-center justify-center gap-2 flex`}
                          onClick={() => handleShowGeneratedConcepts()}
                        >
                          <span className="text-[#71717A] text-base font-bold">
                            Cancel
                          </span>
                        </button>
                        <button
                          className={`w-[164px] h-12 rounded-lg bg-[#3B82F6] outline-none flex items-center justify-center gap-2 ${showLoading ? 'opacity-60' : ''}`}
                          onClick={() => saveBrainStormConceptDetails()}
                          disabled={showLoading}
                        >
                          <span className="text-white text-base font-bold">
                            Save
                          </span>
                          {showLoading &&
                            <BiLoaderAlt className="w-6 h-6 text-[#A1A1AA] animate-spin" />
                          }
                        </button>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </>
      )}

      {brainstormData && brainstormData.fromSource && (
        <SourceMaterialDrawer
          visible={visible}
          fileList={brainstormData.file_list}
          onClose={() => setVisible(false)}
        />
      )}
      {brainstormData &&
        <BrainStormEditModal
          open={editModalOpen}
          brainstorm={brainstormData}
          handleClose={() => setEditModalOpen(false)}
          onEdit={() => getBrainstormDetail()}
        />
      }
    </div>
  )
}

export default BrainStormModal
