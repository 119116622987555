export const parseJWT = (token: any) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

export const getExtension = (fileName: any) => {
  return fileName?.split('.')?.pop()
}

export const exportTextFile = (filename: string, text: string) => {
  const element = document.createElement('a')
  const file = new Blob([text], { type: 'text/plain' })
  element.href = URL.createObjectURL(file)
  element.download = filename // Set the file name here
  document.body.appendChild(element) // Required for this to work in Firefox
  element.click()
}

export const getDocumentName = (filePath: string): string => {
  // Split the path by both backslash and forward slash and get the last element
  const parts = filePath.split(/[\\/]/)
  if (parts.length > 0) return parts[parts.length - 1]
  return ''
}

export const extractQAPairs = (messages: any[]): any[] => {
  const qaPairs: any[] = []

  for (let i = 0; i < messages?.length; i++) {
    if (messages[i]?.isUserMessage) {
      const question = messages?.[i]?.text
      const answerMessage = messages?.[i + 1]
      if (answerMessage && !answerMessage?.isUserMessage) {
        const answer = answerMessage?.text
        qaPairs.push({ question, answer })
      }
    }
  }

  return qaPairs
}

export const generateUniqueId = () => {
  const timestamp = Date.now()
  const randomNumber = Math.random()
  const hexadecimalString = randomNumber.toString(16)
  return `id-${timestamp}-${hexadecimalString}`
}
