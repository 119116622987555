// src/PrivateRoute.tsx
import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useUserContext } from '../contexts/UserContext';
import PageLoading from '../Components/common/PageLoading';
interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  const { isLoaded, isSignedIn } = useUserContext();

  if (!isLoaded) {
    return <PageLoading></PageLoading>
  }

  return (
    <Route
      {...rest}
      render={props =>
        isSignedIn ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;
