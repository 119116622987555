import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { registerLicense } from '@syncfusion/ej2-base'
import AudioRecorder from 'audio-recorder-polyfill'
import { ClerkProvider } from '@clerk/clerk-react'
import { UserProvider } from './contexts/UserContext'
import ErrorPage from './ErrorPage'
import useGlobalErrorHandler from './useGlobalErrorHandler'

window.MediaRecorder = AudioRecorder
const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY

if (!PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key')
}
registerLicense(
  'ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5QdEBiWXtZc3FdQWNY',
)

const Main: React.FC = () => {
  const hasError = useGlobalErrorHandler()

  if (hasError) {
    return <ErrorPage />
  }

  return (
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <UserProvider>
          <App />
      </UserProvider>
    </ClerkProvider>
  )
}
ReactDOM.render(<Main />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
