import React, { useEffect, useState } from 'react'
import { Editor } from './SecondPage/Editor'
interface MainProps {
  openedDocument: string
  documentUpdated: boolean
  positionFirst: any
  positionSecond: any
  selectedFolderID: string
  pageNumber: number
}

const Main: React.FC<MainProps> = ({
  openedDocument,
  documentUpdated,
  positionFirst,
  positionSecond,
  selectedFolderID,
  pageNumber,
}) => {
  return (
    <div className="flex-1 h-full text-center bg-white rounded-xl relative" id="editor-panel">
      <div>
        <Editor
          openedDocument={openedDocument}
          documentUpdated={documentUpdated}
          positionFirst={positionFirst}
          positionSecond={positionSecond}
          selectedFolderID={selectedFolderID}
          pageNumber={pageNumber}
        ></Editor>
      </div>
    </div>
  )
}

export default Main
