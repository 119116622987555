import React, { useEffect, useState } from 'react'
import { HiOutlineFolderMinus, HiOutlineFolderPlus } from 'react-icons/hi2'
import { useRagContext } from '../../../../contexts/RagContext'
import BpCheckbox from '../../../common/BPCheckbox'
import $ from 'jquery'

interface SourceMaterialFolderComponentProps {
  folder: any
  fileList: any
}

interface TreeNode {
  id: string
  name: string
  children?: TreeNode[]
  path: string
  secured_path: string
  isExpanded?: boolean
  isFolder?: boolean
  isEdit?: boolean
  isCreate?: boolean
  isRequireOCR: boolean
  folder_id: string
  isOCRProcessing: boolean
}

const SourceMaterialFolder: React.FC<SourceMaterialFolderComponentProps> = ({
  folder,
  fileList,
}) => {
  const [tree, setTree] = useState<TreeNode[]>([])
  const { folderList, getFilePath } = useRagContext()

  useEffect(() => {
    if (folder?.structure) {
      setTree(buildTree(folder?.structure))
    }
  }, [folder?.structure])

  const isSelectedBrainstormFolder = (folder_id: any): boolean => {
    let isSelected = false
    folderList.forEach((parentFolder) => {
      if (
        isSelectedBrainstormSubFolder(
          parentFolder?.structure[Object.keys(parentFolder?.structure)[0]],
          folder_id,
        )
      ) {
        isSelected = true
      }
    })

    return isSelected
  }

  const isSelectedBrainstormSubFolder = (
    folder: any,
    folder_id: any,
  ): boolean => {
    if (folder.id === folder_id) {
      let isSelected = true
      if (Object.keys(folder.children).length === 0) return false
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key]
        if (
          !(
            (item.isFolder && isSelectedBrainstormSubFolder(item, item.id)) ||
            (!item.isFolder && isSelectedBrainstormFile(key, folder_id))
          )
        ) {
          isSelected = false
        }
      })
      return isSelected
    } else {
      let isSelected = false
      if (Object.keys(folder.children).length === 0) return false
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key]
        if (item.isFolder && isSelectedBrainstormSubFolder(item, folder_id)) {
          isSelected = true
        }
      })
      return isSelected
    }
  }

  const isSelectedBrainstormFile = (
    file_name: any,
    folder_id: string,
  ): boolean => {
    if (fileList.find((e: any) => e === getFilePath(file_name, folder_id))) {
      return true
    } else {
      return false
    }
  }

  const toggleNode = (e: any, node: TreeNode) => {
    node.isExpanded = !node.isExpanded
    setTree([...tree])
    const slashCount = (node.path.match(/\//g) || []).length || 1
    let folderContainer: any = $('#start-brainstorm-folder-container')
    if (folderContainer) {
      folderContainer.animate({ scrollLeft: 50 * slashCount }, 500)
    }
  }

  const buildTree = (
    data: any,
    path: string = '',
    secured_path: string = '',
  ): TreeNode[] => {
    return Object.keys(data).map((key) => ({
      folder_id: folder.id,
      id: data[key].id,
      name: key,
      children: buildTree(
        data[key].children || {},
        path + '/' + key,
        secured_path + '/' + data[key].id,
      ),
      path: path + '/' + key,
      secured_path: secured_path,
      isExpanded: false,
      isFolder: data[key]?.isFolder ? data[key]?.isFolder : false,
      isEdit: false,
      isCreate: data[key]?.isCreate,
      isRequireOCR: data[key]?.isRequireOCR ? data[key]?.isRequireOCR : false,
      isOCRProcessing: false,
    }))
  }

  const renderTree = (nodes: TreeNode[], folderId?: string) => (
    <>
      {nodes.map((node, index) => (
        <div key={index}>
          {node.isFolder === true ? (
            <>
              <div
                key={index}
                className={`px-3 py-[10px] flex items-start gap-3 rounded-[10px] group sidebar-folder w-[400px]`}
              >
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    toggleNode(e, node)
                  }}
                  className="outline-none flex-none"
                >
                  {node.isExpanded ? (
                    <HiOutlineFolderMinus className="text-2xl text-[#3B82F6] flex-none" />
                  ) : (
                    <HiOutlineFolderPlus className="text-2xl text-[#3B82F6] flex-none" />
                  )}
                </button>
                <p
                  className="text-base font-medium text-[#3B82F6] folder-name text-break min-w-[130px]"
                  onClick={(e) => {
                    toggleNode(e, node)
                  }}
                >
                  {node.name}
                </p>
                <button
                  className={`outline-none flex-none ml-auto flex h-6 items-center ${
                    isSelectedBrainstormFolder(node.id)
                      ? 'visible'
                      : 'invisible'
                  }`}
                >
                  <BpCheckbox
                    size="small"
                    className="w-[14px] h-[14px]"
                    checked={isSelectedBrainstormFolder(node.id)}
                  ></BpCheckbox>
                </button>
              </div>
              {node.isExpanded && (
                <div
                  className={`relative pl-9 flex-col sidebar-folder-items ${
                    node.isExpanded ? 'flex' : 'hidden'
                  }`}
                >
                  {node.children && node.children.length > 0 ? (
                    renderTree(node.children, node.id)
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              <div
                className={`w-[400px] sidebar-folder-item px-3 py-[10px] rounded-xl flex items-start gap-1 justify-between cursor-pointer group bg-transparent text-[#52525B]`}
              >
                <p className={`text-base document-title`}>{node.name}</p>
                <div className="flex gap-2 ml-auto">
                  <button
                    className={`outline-none flex-none ml-auto flex h-6 items-center ${
                      isSelectedBrainstormFile(node.name, folderId as string)
                        ? 'visible'
                        : 'invisible'
                    }`}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <BpCheckbox
                      size="small"
                      className="w-[14px] h-[14px]"
                      checked={isSelectedBrainstormFile(
                        node.name,
                        folderId as string,
                      )}
                    ></BpCheckbox>
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      ))}
    </>
  )
  return <>{renderTree(tree)}</>
}

export default SourceMaterialFolder
