import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { ToastContainer, toast, Flip } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { RouteComponentProps } from 'react-router'
import { useLocation } from 'react-router-dom'
import logoImage from '../../assets/auth/logo.svg'
import avatarImage from '../../assets/auth/example-avatar.png'
import bgImage from '../../assets/auth/set-password-bg-image.png'
import circleImage from '../../assets/auth/register-circle-bg.svg'
import { useSignUp } from "@clerk/clerk-react";
import { useHistory } from 'react-router'
type SomeComponentProps = RouteComponentProps
const SetPassword: FC<SomeComponentProps> = ({ }): JSX.Element => {
    const { signUp } = useSignUp();
    const history = useHistory();
    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm()
    const location = useLocation()
    const [email, setEmail] = useState('')
    const [clerkStatus, setClerkStatus] = useState('')
    const [clerkTicket, setClerkTicket] = useState('')
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const status = searchParams.get('__clerk_status')
        const ticket = searchParams.get('__clerk_ticket')
        if (status && ticket) {
            setClerkStatus(status)
            setClerkTicket(ticket)
        }
    }, [location.search])

    const setPassword = async (data: any) => {
        try {
            if (clerkStatus === 'sign_up' && clerkTicket != '') {
                const response = await signUp?.create({
                    strategy: "ticket",
                    'ticket': clerkTicket,
                    password: data.password,
                    unsafeMetadata: {
                        'user_name': data.user_name
                    },
                });
                toast.success('You accepted invitation successfully', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });
                setTimeout(() => {
                    history.push('/')
                }, 1000)
            } else {
                toast.error('You are failed to accept this invitation', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });
            }
        } catch (error) {
            toast.error('You are failed to accept this invitation', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
            });
        }
    }

    return (
        <>
            <div className="w-screen h-screen font-Satoshi">
                <div className="flex h-full">
                    <div className="h-full flex flex-col justify-between py-[72px] px-24 bg-white w-[700px]">
                        <div className="flex items-center gap-2">
                            <img
                                src={logoImage}
                                alt="Logo"
                                className="w-12 h-12 rounded-full flex-none"
                            />
                            <div className="">
                                <div className="text-[22px] leading-[27px] tracking-[-3%] text-[#27272A] font-Host-Grotesk font-medium">
                                    Profitable Signals
                                </div>
                                <div className="text-[13px] leading-[16px] tracking-[-2%] text-[#71717A] font-Host-Grotesk font-normal">
                                    Researcher
                                </div>
                            </div>
                        </div>
                        <form autoComplete="off" onSubmit={handleSubmit(setPassword)}>
                            <div className="flex flex-col gap-4">
                                <div>
                                    <div className="text-heading-4 leading-heading-4 text-[#232323] font-bold mb-2">
                                        Accept your invitation
                                    </div>
                                    <div className="text-xl text-[#969696] font-normal">
                                        Create your new passwoerd
                                    </div>
                                </div>
                                <div className="w-full">
                                    <label
                                        htmlFor="user_name"
                                        className="text-base text-[#27272A] mb-1 ml-1"
                                    >
                                        Fullname
                                    </label>
                                    <input
                                        type="text"
                                        id="user_name"
                                        className="w-full h-12 border border-[#A1A1AA] rounded-lg px-4 py-3 text-sm leading-6 text-[#71717A] outline-none"
                                        placeholder="Enter your full name"
                                        {...register('user_name', {
                                            required: 'User name is required!',
                                        })}
                                    />
                                    {errors.user_name && (
                                        <div className="text-red-600 text-xs leading-5 no-underline mt-1">
                                            {errors.user_name.message}
                                        </div>
                                    )}
                                </div>
                                <div className="flex flex-col gap-5">
                                    <div className="w-full">
                                        <label
                                            htmlFor="password"
                                            className="text-base text-[#27272A] mb-1 ml-1"
                                        >
                                            Create New Password
                                        </label>
                                        <input
                                            type="password"
                                            id="password"
                                            className="w-full h-12 border border-[#A1A1AA] rounded-lg px-4 py-3 text-sm leading-6 text-[#71717A] outline-none"
                                            placeholder="Enter new poassword"
                                            {...register('password', {
                                                required: 'Password is required!',
                                                minLength: {
                                                    value: 6,
                                                    message:
                                                        'Password should be at least 6 characters long',
                                                },
                                                pattern: {
                                                    value: /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
                                                    message:
                                                        'Password should contain at least one number and one symbol',
                                                },
                                            })}
                                        />
                                        {errors.password && (
                                            <div className="text-red-600 text-xs leading-5 no-underline mt-1">
                                                {errors.password.message}
                                            </div>
                                        )}
                                    </div>
                                    <div className="w-full">
                                        <label
                                            htmlFor="cpassword"
                                            className="text-base text-[#27272A] mb-1 ml-1"
                                        >
                                            Confirm Password
                                        </label>
                                        <input
                                            type="password"
                                            id="cpassword"
                                            className="w-full h-12 border border-[#A1A1AA] rounded-lg px-4 py-3 text-sm leading-6 text-[#71717A] outline-none"
                                            placeholder="Re-enter password "
                                            {...register('cpassword', {
                                                required: 'Confirm Password is required',

                                                validate: (value) =>
                                                    value === watch('password') ||
                                                    "Passwords doesn't match.",
                                            })}
                                        />
                                        {errors.cpassword && (
                                            <div
                                                className="text-red-600 text-xs leading-5 no-underline mt-1"
                                                style={{ fontSize: 14 }}
                                            >
                                                {errors.cpassword.message}
                                            </div>
                                        )}
                                    </div>
                                    <button className="w-full h-12 bg-[#3B82F6] rounded-lg py-3 px-6 flex justify-center items-center">
                                        <span className="text-base font-bold text-white">
                                            Set Password
                                        </span>
                                    </button>
                                </div>
                                <div className="text-lg leading-[26px] font-medium text-center text-[#71717A]">
                                    <Link to={'/login'}>Back to Sign in</Link>
                                </div>
                            </div>
                        </form>
                        <div className="h-12"></div>
                    </div>
                    <div className="flex-1 h-full relative bg-[#DBEAFE] overflow-hidden">
                        {/* <div className="mt-[140px] w-full pl-[72px] pr-[100px] relative z-30">
                            <div className="text-[32px] leading-10 text-[#232323] font-bold mb-[18px] tracking-[-1px]">
                                Lorem ipsum dolor sit amet consectetur. Nulla varius risus augue
                                erat morbi.
                            </div>
                            <div className="flex items-center gap-1">
                                <img
                                    src={avatarImage}
                                    alt="Avatar"
                                    className="w-10 h-10 rounded-full flex-none"
                                />
                                <div className="">
                                    <div className="text-sm leading-[22px] font-bold text-black">
                                        James Bator
                                    </div>
                                    <div className="text-[10px] leading-[14px] text-[#838c98] font-normal">
                                        CEO of profitable signal
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <img
                            src={bgImage}
                            alt="background"
                            className="absolute bottom-0 left-0 h-[64.45%] z-20"
                        />
                        <img
                            src={circleImage}
                            alt="circle"
                            className="absolute bottom-0 right-0 h-1/2 z-10"
                        />
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
                limit={1}
                transition={Flip}
            />
        </>
    )
}
export default SetPassword