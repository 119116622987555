import React, { useState, useEffect } from 'react'
import $ from 'jquery'
import { toast } from 'react-toastify'
import { Tabs, Tab } from 'react-tabs-scrollable'
import drawerCloseIcon from '../../assets/svg/drawer-close.svg'
import drawerOpenIcon from '../../assets/svg/drawer-open.svg'
import logo from '../../assets/auth/logo.svg'
import searchIcon from '../../assets/svg/search.svg'
import { GrLinkNext } from 'react-icons/gr'
import axios from 'axios'
import Folder from './Folder'
import { useUserContext } from '../../contexts/UserContext'
import { useRagContext } from '../../contexts/RagContext'
import ConceptFolder from './ConceptFolder'
import 'react-tabs-scrollable/dist/rts.css'
import moment from 'moment'
import { useHistory } from 'react-router'

interface SidebarComponentProps {
  handleDocumentClick: any
  positionFirst: any
  openedDocument: string
  setSelectedFolderID: any
  setSelectedSubFolderID: any
  selectedSubFolderID: string
}

export interface MyCustomCSS extends React.CSSProperties {
  '--line-clamp': number
  '--width': string
  '--font-size': string
  '--align': string
}

const Sidebar: React.FC<SidebarComponentProps> = ({
  handleDocumentClick,
  positionFirst,
  openedDocument,
  setSelectedFolderID,
  setSelectedSubFolderID,
  selectedSubFolderID,
}) => {
  const [extraWidth, setExtraWidth] = useState(0)

  useEffect(() => {
    handleResize()
  }, [positionFirst?.positionFirstX])

  const handleResize = () => {
    let width: any = positionFirst?.positionFirstX
    let chatPanel = document.getElementById('chat-panel')
    if (chatPanel) {
      let widthForAIControl = chatPanel.offsetWidth
      let scaleWidth = window.innerWidth - widthForAIControl - 308
      if (width > 0) {
        if (width > scaleWidth) width = scaleWidth
      } else {
        width = 0
      }
      setExtraWidth(width)
      let widthForSidePanel = 300 + extraWidth
      let widthForEditor =
        window.innerWidth - widthForAIControl - widthForSidePanel - 20
      if (widthForEditor < 20) widthForEditor = 0
      let editor = document.getElementById('editor-panel')
      if (editor) editor.style.width = widthForEditor + 'px'
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const [open, setOpen] = useState(true)
  const [searchText, setSearchText] = useState('')
  const [shownFolderList, setShownFolderList] = useState<any[]>([])
  const { user } = useUserContext()
  const history = useHistory()
  const {
    folderList,
    selectedFiles,
    conceptFolders,
    brainstormLists,
    setting,
    socketId,
    getFilePath,
    setFolderList,
    openConceptAddFolderModal,
    getConceptFolders,
    getBrainstormList,
    openConceptManagerModal,
    openFileManagerModal,
    openShareFeedbackModal,
    openStartBrainstormDrawer,
    openCreateConceptDrawer,
  } = useRagContext()
  const [activeTab, setActiveTab] = useState(0)

  const onTabClick = (e: any, index: number) => {
    setActiveTab(index)
  }

  useEffect(() => {
    if (
      user?.organizationMemberships[0]?.organization.id &&
      setting?.verified
    ) {
      fetchFolders()
      getConceptFolders()
      getBrainstormList()
    }
  }, [user?.organizationMemberships[0]?.organization.id, setting])

  useEffect(() => {
    if (searchText === '') {
      setShownFolderList(folderList)
    } else {
      const searchTextLower = searchText.toLowerCase()
      const tempFolderList = JSON.parse(JSON.stringify(folderList))

      const traverseAndFilter = (node: any) => {
        const keysToRemove: string[] = []

        Object.keys(node).forEach((key) => {
          if (key.toLowerCase().indexOf(searchTextLower) === -1) {
            keysToRemove.push(key)
          } else if (node[key].children) {
            traverseAndFilter(node[key].children)
          }
        })

        keysToRemove.forEach((key) => {
          delete node[key]
        })
      }

      tempFolderList.forEach((folder: any) => {
        traverseAndFilter(folder.structure)
      })

      setShownFolderList(tempFolderList)
    }
  }, [folderList, searchText])

  const fetchFolders = async () => {
    try {
      const result = await axios.post(
        process.env.REACT_APP_API_URL + 'api/getFolders',
        {
          team_id: user?.organizationMemberships[0]?.organization.id,
        },
      )
      if (result.data.data.length > 0) {
        setSelectedFolderID(result.data.data[0]?.id)
      }
      setFolderList(result.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleStartOCR = async (
    id: string,
    secured_path: string,
    target_path: string,
    file_name: string,
  ) => {
    try {
      const result = await axios.post(
        process.env.REACT_APP_API_URL + 'api/startOCR',
        {
          folder_id: id,
          file_name: file_name,
          target_path: target_path,
          secured_path: secured_path,
          team_id: user?.organizationMemberships[0]?.organization.id,
        },
      )
      if (result.status === 200) {
        fetchFolders()
        toast.success('OCR processed successfully', {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: 'my_toast',
        })
      }
    } catch (error) { }
  }
  const deleteFolder = async (
    id: string,
    path: string,
    secured_path: string,
  ) => {
    try {
      const result = await axios.post(
        process.env.REACT_APP_API_URL + 'api/deleteFolder',
        {
          id: id,
          path: path,
          secured_path: secured_path,
          sub_folder_id: selectedSubFolderID,
          team_id: user?.organizationMemberships[0]?.organization.id,
        },
      )
      if (result.status === 200) {
        fetchFolders()
        toast.success('Folder Deleted!', {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: 'my_toast',
        })
      }
    } catch (error) { }
  }

  const saveFolder = async (
    id: string,
    node_id: string,
    folder_name: string,
    path: string,
  ) => {
    try {
      let isCreating = node_id === undefined ? true : false
      if (isCreating) {
        const result = await axios.post(
          process.env.REACT_APP_API_URL + 'api/createFolder',
          {
            folder_name: folder_name,
            old_path: path,
            id: id,
            team_id: user?.organizationMemberships[0]?.organization.id,
          },
        )
        if (result.status === 200) {
          fetchFolders()
          toast.success('Folder Created!', {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: 'my_toast',
          })
        }
      } else {
        const result = await axios.post(
          process.env.REACT_APP_API_URL + 'api/updateFolder',
          {
            id: id,
            old_path: path,
            folder_name: folder_name,
          },
        )
        if (result.status === 200) {
          toast.success('Folder Updated!', {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: 'my_toast',
          })
          fetchFolders()
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const dbSaveSuccess = async () => {
    fetchFolders()
  }

  const handleToggleOpen = (status: boolean) => {
    setOpen(!open)
    setTimeout(() => {
      let folderPanel = document.getElementById('folder-panel')
      let widthForSidePanel = 299
      let widthForAIControl = 424
      if (folderPanel) {
        widthForSidePanel = parseInt(folderPanel.style.width, 10)
      }
      let chatPanel = document.getElementById('chat-panel')
      if (chatPanel) {
        widthForAIControl = chatPanel.offsetWidth
      }
      let editorMaxWidth =
        window.innerWidth - widthForAIControl - widthForSidePanel - 8
      let editor = document.getElementById('editor-panel')
      if (editor) editor.style.width = editorMaxWidth + 'px'
      let pdfviewerObject: any = $('#container')
      let pdfViewerContainer = pdfviewerObject[0]?.ej2_instances[0]
      let documentObject: any = $('#docx_container')
      let documentContainer: any =
        documentObject[0]?.ej2_instances[0]?.documentEditor
      if (documentContainer) {
        documentContainer.resize()
      }
      if (pdfViewerContainer) {
        if (pdfViewerContainer?.isRendered) {
          pdfViewerContainer.updateViewerContainer()
        }
      }
    }, 100)
  }

  const documentClick = (document: string, folder_id: string) => {
    handleDocumentClick(document, folder_id)
  }

  const handleCreateNewChat = () => {
    window.postMessage(
      {
        type: 'create_new_chat',
      },
      '*',
    )
  }

  const handleGetHMWQuestions = async () => {
    try {
      let fileList = selectedFiles.map((file: any) => {
        return getFilePath(file.file_name, file.folder_id)
      })
      let params: any = {
        team_id: user?.organizationMemberships[0]?.organization.id,
        file_list: fileList,
        name: 'Brainstorm ' + moment().format('YYYY-MM-DD h:mm a'),
        context: '',
        fromSource: true,
        socketId,
      }
      if (fileList.length > 0) {
        params.pinecone_id = fileList[0].split('/')[2]
      } else if (folderList.length > 0) {
        params.pinecone_id = folderList[0].id
      } else {
        params.pinecone_id = ''
      }
      const result = await axios.post(
        process.env.REACT_APP_API_URL + 'api/getHMWQuestions',
        params,
      )

      const searchParams = new URLSearchParams()
      searchParams.set('render_type', 'modal')
      searchParams.set('render_modal', 'brainstorm_modal')
      searchParams.set('brainstorm_id', result.data.brainstorm_id)

      history.push({
        pathname: '/',
        search: `?${searchParams.toString()}`,
      })
      getBrainstormList()
    } catch (error) {
      console.log(error)
    }
  }

  const openBrainstormModal = (id: string) => {
    const searchParams = new URLSearchParams()
    searchParams.set('render_type', 'modal')
    searchParams.set('render_modal', 'brainstorm_modal')
    searchParams.set('brainstorm_id', id)

    history.push({
      pathname: '/',
      search: `?${searchParams.toString()}`,
    })
  }

  return (
    <div
      className="flex flex-col"
      id="folder-panel"
      style={{
        width: `${open ? `${300 + extraWidth}px` : '96px'}`,
      }}
    >
      <div
        className="flex justify-between items-center py-8"
        style={{ paddingLeft: open ? 24 : 28, paddingRight: open ? 14 : 28 }}
      >
        <div
          className={`gap-2 items-center overflow-hidden w-fit ${open ? 'flex' : 'hidden'
            }`}
        >
          <img
            src={logo}
            alt="Logo"
            className="w-[42px] h-[42px] rounded-full flex-none"
          />
          <div>
            <div className="text-xl leading-6 tracking-[-3%] text-[#27272A] font-medium font-Host-Grotesk">
              Profitable Signals
            </div>
            <div className="text-xs leading-[14px] tracking-[-2%] text-[#71717A] font-normal font-Host-Grotesk">
              Researcher
            </div>
          </div>
        </div>
        <button
          className="w-10 h-10 border boder-1 border-[#D4D4D8] rounded-md flex items-center justify-center outline-none flex-none"
          onClick={() => handleToggleOpen(open)}
        >
          <img
            src={open ? drawerOpenIcon : drawerCloseIcon}
            alt="Open"
            className="w-[22px] h-[22px]"
          />
        </button>
      </div>
      <div
        className="mb-6 border-b border-1 border-[#E4E4E7]"
        hidden={open}
      ></div>
      <div className={`flex flex-col gap-4 flex-1 ${open ? 'px-5' : 'px-6'}`}>
        <div
          className={`relative w-full rounded-xl bg-white px-4 py-3 gap-3 items-center ${open ? 'flex' : 'hidden'
            }`}
        >
          <img src={searchIcon} alt="Search" className="w-6 h-6 flex-none" />
          <input
            type="text"
            className="flex-1 text-sm leading-[22px] text-[#A1A1AA] font-medium outline-none"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search or type a command"
          />
        </div>
        {extraWidth < 100 ? (
          <div className="!w-[calc(100%+56px)] relative">
            <Tabs
              activeTab={activeTab}
              onTabClick={onTabClick}
              mode="scrollSelectedToCenterFromView"
              leftNavBtnClassName="!invisible"
              rightNavBtnClassName="!invisible"
              tabsContainerClassName="!-translate-x-[28px]"
            >
              <Tab
                key={0}
                className={`!py-1 !px-6 w-full !m-0 flex-none !rounded-none !bg-transparent !shadow-none ${activeTab === 0
                    ? '!border-t-0 !border-x-0 !border-b !border-solid !border-[#18181B]'
                    : '!border-none'
                  }`}
                style={{ width: 110 + extraWidth / 2 + 'px' }}
              >
                <div
                  className={`w-full h-6 flex items-center justify-center font-bold text-base ${activeTab === 0 ? 'text-[#18181B]' : 'text-[#71717A]'
                    }`}
                >
                  Sources
                </div>
              </Tab>
              <Tab
                key={1}
                className={`!py-1 !px-6 w-full !m-0 flex-none !rounded-none !bg-transparent !shadow-none ${activeTab === 1
                    ? '!border-t-0 !border-x-0 !border-b !border-solid !border-[#18181B]'
                    : '!border-none'
                  }`}
                style={{ width: 110 + extraWidth / 2 + 'px' }}
              >
                <div
                  className={`w-full h-6 flex items-center justify-center font-bold text-base ${activeTab === 1 ? 'text-[#18181B]' : 'text-[#71717A]'
                    }`}
                >
                  Concept
                </div>
              </Tab>
              <Tab
                key={2}
                className={`!py-1 !px-6 w-full !m-0 flex-none !rounded-none !bg-transparent !shadow-none ${activeTab === 2
                    ? '!border-t-0 !border-x-0 !border-b !border-solid !border-[#18181B]'
                    : '!border-none'
                  }`}
                style={{ width: 110 + extraWidth / 2 + 'px' }}
              >
                <div
                  className={`w-full h-6 flex items-center justify-center font-bold text-base ${activeTab === 2 ? 'text-[#18181B]' : 'text-[#71717A]'
                    }`}
                >
                  Brainstorm
                </div>
              </Tab>
            </Tabs>
            {activeTab !== 0 && (
              <div className="absolute top-0 left-0 h-full w-[18px] bg-gradient-to-r from-[#F4F4F5] to-[#f4f4f56b]"></div>
            )}
            {activeTab !== 2 && (
              <div className="absolute top-0 right-14 h-full w-[18px] bg-gradient-to-r from-[#f4f4f56b] to-[#F4F4F5]"></div>
            )}
          </div>
        ) : (
          <div className={`w-full grid-cols-3 ${open ? 'grid' : 'hidden'}`}>
            <button
              className={`py-1 px-6 w-full cursor-pointer ${activeTab === 0 ? 'border-b border-solid border-[#18181B]' : ''
                }`}
              onClick={() => {
                if (activeTab !== 0) setActiveTab(0)
              }}
              disabled={!setting?.verified}
            >
              <div
                className={`w-full h-6 flex items-center justify-center font-bold text-base ${activeTab === 0 ? 'text-[#18181B]' : 'text-[#71717A]'
                  }`}
              >
                Sources
              </div>
            </button>
            <button
              className={`py-1 px-6 w-full cursor-pointer ${activeTab === 1 ? 'border-b border-solid border-[#18181B]' : ''
                }`}
              onClick={() => {
                if (activeTab !== 1) setActiveTab(1)
              }}
              disabled={!setting?.verified}
            >
              <div
                className={`w-full h-6 flex items-center justify-center font-bold text-base ${activeTab === 1 ? 'text-[#18181B]' : 'text-[#71717A]'
                  }`}
              >
                Concept
              </div>
            </button>
            <button
              className={`py-1 px-6 w-full cursor-pointer ${activeTab === 2 ? 'border-b border-solid border-[#18181B]' : ''
                }`}
              onClick={() => {
                if (activeTab !== 2) setActiveTab(2)
              }}
              disabled={!setting?.verified}
            >
              <div
                className={`w-full h-6 flex items-center justify-center font-bold text-base ${activeTab === 2 ? 'text-[#18181B]' : 'text-[#71717A]'
                  }`}
              >
                Brainstorm
              </div>
            </button>
          </div>
        )}
        <div className="flex flex-col justify-between h-[calc(100vh-250px)]">
          <div
            className={`flex-1 overflow-y-auto overflow-x-hidden ${activeTab === 0 ? 'block' : 'hidden'
              }`}
          >
            <div className="" hidden={!open}>
              <div className="flex items-center justify-between">
                <div className="py-1 px-[10px] text-sm leading-[22px] font-bold text-[#00000067]">
                  {shownFolderList.length || ''} Folders
                </div>
                <button
                  className="p-0 outline-none border-none bg-transparent text-[#2563EB] text-sm leading-[22px] font-bold"
                  onClick={() => openFileManagerModal()}
                  disabled={!setting?.verified}
                >
                  Manage
                </button>
              </div>
              <div
                className={`overflow-x-auto overflow-y-auto ${selectedFiles.length
                    ? 'h-[calc(100vh-512px)]'
                    : 'h-[calc(100vh-338px)]'
                  }`}
                style={{ width: `${242 + extraWidth}px` }}
                id="folder-container"
              >
                {shownFolderList.map((folder, index) => {
                  return folder.isFolderDeleted === 1 ? (
                    <div key={index}></div>
                  ) : (
                    <Folder
                      key={index}
                      folder={folder}
                      saveFolder={saveFolder}
                      dbSaveSuccess={dbSaveSuccess}
                      documentClick={documentClick}
                      openedDocument={openedDocument}
                      deleteFolder={deleteFolder}
                      handleStartOCR={handleStartOCR}
                      setSelectedFolderID={setSelectedFolderID}
                      setSelectedSubFolderID={setSelectedSubFolderID}
                      selectedSubFolderID={selectedSubFolderID}
                      extraWidth={extraWidth}
                    ></Folder>
                  )
                })}
              </div>
              <div
                className={`flex-col gap-[10px] mt-[10px] ${selectedFiles.length ? 'flex' : 'hidden'
                  }`}
              >
                <button
                  className="w-full h-12 rounded-xl bg-[#3B82F6] outline-none flex items-center justify-center gap-2"
                  onClick={() => openConceptAddFolderModal('selected_source')}
                >
                  <span className="text-white text-base font-bold font-Satoshi">
                    Create New Concept
                  </span>
                  <GrLinkNext className="text-white text-base font-bold font-Satoshi" />
                </button>
                <button
                  className="w-full h-12 rounded-xl bg-[#3B82F6] outline-none flex items-center justify-center gap-2"
                  onClick={() => handleCreateNewChat()}
                >
                  <span className="text-white text-base font-bold font-Satoshi">
                    Create New Chat
                  </span>
                  <GrLinkNext className="text-white text-base font-bold font-Satoshi" />
                </button>
                <button
                  className="w-full h-12 rounded-xl bg-[#3B82F6] outline-none flex items-center justify-center gap-2"
                  onClick={() => handleGetHMWQuestions()}
                >
                  <span className="text-white text-base font-bold font-Satoshi">
                    Start Brainstorm
                  </span>
                  <GrLinkNext className="text-white text-base font-bold font-Satoshi" />
                </button>
              </div>
            </div>
          </div>
          <div
            className={`flex-1 overflow-y-auto overflow-x-hidden ${activeTab === 1 ? 'block' : 'hidden'
              }`}
          >
            <div className="flex items-center justify-between">
              <div className="py-1 px-[10px] text-sm leading-[22px] font-bold text-[#00000067]">
                {conceptFolders.length} Folders
              </div>
              <button
                className="p-0 outline-none border-none bg-transparent text-[#2563EB] text-sm leading-[22px] font-bold"
                onClick={() => openConceptManagerModal()}
                disabled={!setting?.verified}
              >
                Manage
              </button>
            </div>
            <div
              className="h-[calc(100vh-398px)] overflow-x-auto overflow-y-auto"
              style={{ width: `${242 + extraWidth}px` }}
              id="concept-container"
            >
              {conceptFolders.map((folder, index) => (
                <ConceptFolder
                  extraWidth={extraWidth}
                  folder={folder}
                  key={index}
                ></ConceptFolder>
              ))}
            </div>
            <button
              className="w-full h-12 rounded-xl bg-[#3B82F6] outline-none flex items-center justify-center gap-2 mt-[10px]"
              onClick={() => openCreateConceptDrawer()}
            >
              <span className="text-white text-base font-bold font-Satoshi">
                Create New Concept
              </span>
              <GrLinkNext className="text-white text-base font-bold font-Satoshi" />
            </button>
          </div>
          <div
            className={`flex-1 overflow-y-auto overflow-x-hidden ${activeTab === 2 ? 'block' : 'hidden'
              }`}
          >
            <div
              className="h-[calc(100vh-368px)] overflow-x-auto overflow-y-auto"
              style={{ width: `${260 + extraWidth}px` }}
              id="brainstorm-container"
            >
              {brainstormLists.map((brainstorm: any, index: number) => (
                <div
                  className="flex items-center h-[30px] mb-1 cursor-pointer"
                  key={index}
                  onClick={() => openBrainstormModal(brainstorm.id)}
                >
                  <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-[22px] font-medium text-[#18181B]">
                    {brainstorm.name}
                  </div>
                </div>
              ))}
            </div>
            <div className={`flex flex-col gap-[10px] mt-[10px]`}>
              <button
                className="w-full h-12 rounded-xl bg-[#3B82F6] outline-none flex items-center justify-center gap-2"
                onClick={() => openStartBrainstormDrawer()}
              >
                <span className="text-white text-base font-bold font-Satoshi">
                  Start Brainstorm
                </span>
                <GrLinkNext className="text-white text-base font-bold font-Satoshi" />
              </button>
            </div>
          </div>

          <button
            className={`w-full h-12 rounded-xl border border-solid border-[#3B82F6] outline-none items-center justify-center gap-2 ${open ? 'flex' : 'hidden'
              }`}
            onClick={() => openShareFeedbackModal('Chat')}
          >
            <span className="text-[#3B82F6] text-base font-bold font-Satoshi">
              Share Feedback
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
