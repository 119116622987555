import React, { useState, useEffect } from 'react'
import Chat from './AIControl/Chat'

interface AIControlComponentProps {
  documentClick: any
  positionSecond: any
  openedDocument: string
  setDocumentUpdated: any
  selectedFolderID: string
  selectedSubFolderID: any
}

const AIControl: React.FC<AIControlComponentProps> = ({
  documentClick,
  positionSecond,
  openedDocument,
  setDocumentUpdated,
  selectedFolderID,
  selectedSubFolderID,
}) => {
  const [extraWidth, setExtraWidth] = useState(0)

  useEffect(() => {
    handleResize()
  }, [positionSecond?.positionSecondX])

  const handleResize = () => {
    let width = positionSecond?.positionSecondX
    let folderPanel = document.getElementById('folder-panel')
    if (folderPanel) {
      let widthForFolderPanel = folderPanel.offsetWidth
      let scaleWidth = window.innerWidth - widthForFolderPanel - 432
      width < 0
        ? width < -scaleWidth
          ? (width = scaleWidth)
          : (width = -width)
        : (width = 0)
      setExtraWidth(width)
      let widthForAIControl = 424 + extraWidth
      let widthForEditor: any =
        window.innerWidth - widthForAIControl - widthForFolderPanel - 25
      if (widthForEditor < 20) {
        widthForEditor = 0
      }
      let editor = document.getElementById('editor-panel')
      if (editor) {
        editor.style.width = widthForEditor + 'px'
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleDocumentClick = (
    document: string,
    folder_id: string = '',
    page: number = 1,
  ) => {
    documentClick(document, folder_id, page)
  }

  return (
    <div
      id="chat-panel"
      className={`pb-8 pt-4 px-6 h-full flex flex-col bg-white z-[10]`}
      style={{
        width: `${424 + extraWidth}px`,
      }}
    >
      <Chat
        handleDocumentClick={handleDocumentClick}
        openedDocument={openedDocument}
        setDocumentUpdated={(val: boolean) => setDocumentUpdated(val)}
        selectedFolderID={selectedFolderID}
        selectedSubFolderID={selectedSubFolderID}
      />
    </div>
  )
}

export default AIControl
