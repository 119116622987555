import React, { FC, useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import './style.css'
import { CssBaseline } from '@mui/material'
import Sidebar from '../../Components/RAG/Sidebar'
import Main from '../../Components/RAG/Main'
import Draggable from 'react-draggable'
import { ViewDrawer } from '../../Components/RAG/ViewDrawer'
import AIControl from '../../Components/RAG/AIControl'
import { ToastContainer, Flip } from 'react-toastify'
import '../../Components/home.css'
import { useUserContext } from '../../contexts/UserContext'
import $ from 'jquery'
import { generateUniqueId, getExtension } from '../../utils'
import ProfileComponent from '../../Components/RAG/Header/ProfileComponent'
import ConceptModal from '../../Components/RAG/ConceptModal/ConceptModal'
import { useRagContext } from '../../contexts/RagContext'
import FileManagerModal from '../../Components/RAG/FileManagerModal/FileManagerModal'
import ConceptSelectFolderModal from '../../Components/RAG/ConceptModal/SelectFolderModal/SelectFolderModal'
import ConteptManagerModal from '../../Components/RAG/ConteptManagerModal/ConteptManagerModal'
import io from 'socket.io-client'
import ActivityLogsModal from '../../Components/RAG/ActivityLogsModal/ActivityLogsModal'
import ShareFeedbackModal from '../../Components/RAG/ShareFeedbackModal/ShareFeedbackModal'
import SettingsModal from '../../Components/RAG/SettingsModal'

type SomeComponentProps = RouteComponentProps
const Rag: FC<SomeComponentProps> = ({ history }) => {
  const { user, isLoaded, isSignedIn } = useUserContext()
  const [openedDocument, setOpenedDocument] = useState('')
  const [selectedFolderID, setSelectedFolderID] = useState('')
  const [selectedSubFolderID, setSelectedSubFolderID] = useState('')
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [positionFirst, setPositionFirst] = useState({ positionFirstX: 0 })
  const [positionSecond, setPositionSecond] = useState({ positionSecondX: 0 })
  const [documentUpdated, setDocumentUpdated] = useState(false)
  const {
    getSettingData,
    setSocketInterface,
    openSettingsModal,
    handleCloseSettingsModal,
  } = useRagContext()
  const onDragFirst = (e: any, data: any) => {
    setPositionFirst({ positionFirstX: data.x })
  }
  const onDragSecond = (e: any, data: any) => {
    setPositionSecond({ positionSecondX: data.x })
  }

  useEffect(() => {
    const socketId = generateUniqueId()
    const socket = io(`${process.env.REACT_APP_API_URL}`, {
      query: { socketId: socketId },
    })

    setSocketInterface(socket, socketId)
  }, [])

  useEffect(() => {
    if (user?.organizationMemberships[0]?.organization.id) {
      getSettingData()
    }
  }, [user?.organizationMemberships[0]?.organization.id])

  const handleDocumentClick = (
    document: string,
    folder_id: string = '',
    page: number = 1,
  ) => {
    setOpenedDocument(document)
    if (!folder_id.includes('org_')) setSelectedFolderID(folder_id)
    else setSelectedFolderID('')
    setPageNumber(page)
    const extension = getExtension(document)
    if (extension == 'pdf' || extension == 'pptx') {
      let pdfviewerObject: any = $('#container')
      let pdfViewer = pdfviewerObject[0]?.ej2_instances?.[0]
      if (pdfViewer) {
        pdfViewer.navigation.goToPage(page)
      }
    } else if (extension == 'docx') {
      let documentObject: any = $('#docx_container')
      let documentViewer = documentObject[0]?.ej2_instances?.[0]?.documentEditor
      if (documentViewer) {
        documentViewer.scrollToPage(page)
      }
    } else if (extension == 'xlsx') {
      let excelObject: any = $('#xlsx_container')
      let excelViewer = excelObject[0]?.ej2_instances?.[0]
      if (excelViewer) {
        excelViewer.activeSheetIndex = page > 0 ? page - 1 : 0
      }

      // console.log()
    }
  }

  return (
    <>
      <div className="font-Satoshi flex h-screen overflow-x-hidden">
        <CssBaseline />
        <Sidebar
          handleDocumentClick={handleDocumentClick}
          positionFirst={positionFirst}
          openedDocument={openedDocument}
          setSelectedFolderID={setSelectedFolderID}
          setSelectedSubFolderID={setSelectedSubFolderID}
          selectedSubFolderID={selectedSubFolderID}
        ></Sidebar>
        <Draggable
          defaultPosition={{ x: 0, y: 0 }}
          defaultClassName="no_trasform"
          position={{ x: 0, y: 0 }}
          onDrag={onDragFirst}
        >
          <div className="z-[1]">
            <ViewDrawer cssDrawer="drawerFirst" cssHandle="handleFirst" />
          </div>
        </Draggable>
        <div className="flex flex-col h-screen w-full">
          <div className="h-[88px] flex-none w-full flex justify-end items-center px-6 bg-white">
            <ProfileComponent />
          </div>
          <div className="flex-1 flex h-[calc(100vh-88px)]">
            <Main
              openedDocument={openedDocument}
              documentUpdated={documentUpdated}
              positionFirst={positionFirst}
              positionSecond={positionSecond}
              selectedFolderID={selectedFolderID}
              pageNumber={pageNumber}
            ></Main>
            <Draggable
              defaultPosition={{ x: 0, y: 0 }}
              defaultClassName="no_trasform"
              position={{ x: 0, y: 0 }}
              onDrag={onDragSecond}
            >
              <div>
                <ViewDrawer cssDrawer="drawerSecond" cssHandle="handleSecond" />
              </div>
            </Draggable>
            <AIControl
              documentClick={handleDocumentClick}
              positionSecond={positionSecond}
              openedDocument={openedDocument}
              setDocumentUpdated={(val: boolean) => setDocumentUpdated(val)}
              selectedFolderID={selectedFolderID}
              selectedSubFolderID={selectedSubFolderID}
            />
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        limit={1}
        transition={Flip}
      />
      <ActivityLogsModal />
      <ShareFeedbackModal />
      <ConceptModal />
      <FileManagerModal />
      <ConceptSelectFolderModal />
      <ConteptManagerModal />
      <SettingsModal
        open={openSettingsModal}
        handleClose={() => handleCloseSettingsModal()}
      />
    </>
  )
}

export default Rag
