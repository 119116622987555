import React from 'react'
import { MdKeyboardArrowRight } from 'react-icons/md'

interface BreadCrumbProps {
  menus: BreadCrumbMenu[]
}

export type BreadCrumbMenu = {
  title: string
  active: boolean
  onClick?: () => void
}

const BreadCrumb: React.FC<BreadCrumbProps> = ({ menus }) => {
  return (
    <div className="w-full px-10 py-4">
      <div className="flex items-center gap-1">
        {menus.map((menu: BreadCrumbMenu, index) => (
          <div className="flex gap-1 items-center" key={index}>
            <button
              className="border-none outline-none"
              onClick={() => {
                if (menu.onClick) {
                  menu.onClick()
                }
              }}
            >
              <span
                className={`text-sm leading-[22px] font-medium ${
                  menu.active ? 'text-[#18181B]' : 'text-[#71717A]'
                }`}
              >{menu.title}</span>
            </button>
            {index !== menus.length - 1 && (
              <MdKeyboardArrowRight className={`w-4 h-4 text-[#18181B]`} />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default BreadCrumb
