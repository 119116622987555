import React, { useState } from 'react'
import conceptIcon from '../../assets/svg/concept.svg'
import conceptExpandedIcon from '../../assets/svg/concept-expanded.svg'
import { FiFileText } from 'react-icons/fi'
import { useRagContext } from '../../contexts/RagContext'
import { useHistory } from 'react-router'

interface FolderComponentProps {
  extraWidth: number
  folder: any
}

const ConceptFolder: React.FC<FolderComponentProps> = ({
  extraWidth,
  folder,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const history = useHistory()

  const openConceptModal = (concept_id: string) => {
    const searchParams = new URLSearchParams()
    searchParams.set('render_type', 'modal')
    searchParams.set('render_modal', 'concept_modal')
    searchParams.set('concept_id', concept_id)

    history.push({
      pathname: '/', // you can change the path here
      search: `?${searchParams.toString()}`, // append your query parameters here
    })
  }

  return (
    <>
      <div className="">
        <div
          style={{
            width: `${242 + extraWidth / 4}px`,
          }}
          className={`px-0 py-2 flex items-center gap-1 group`}
          onClick={(e) => {
            e.preventDefault()
            setIsOpen((open) => !open)
          }}
        >
          <button className="outline-none flex-none">
            {isOpen ? (
              <img src={conceptIcon} alt="" className="w-6 h-6 flex-none" />
            ) : (
              <img
                src={conceptExpandedIcon}
                alt=""
                className="w-6 h-6 flex-none"
              />
            )}
          </button>
          <button className="outline-none flex-none border-none">
            <FiFileText
              className={`w-6 h-6 ${
                isOpen ? 'text-[#3B82F6]' : 'text-[#18181B]'
              }`}
            />
          </button>
          <p
            style={{
              minWidth: `${130 + extraWidth / 4}px`,
            }}
            className={`text-base font-medium folder-name text-break ${
              isOpen ? 'text-[#3B82F6]' : 'text-[#18181B]'
            }`}
          >
            {folder.folder_name}
          </p>
        </div>
        <div
          className={`flex-col w-[calc(100%-28px)] ml-auto ${
            isOpen ? 'flex' : 'hidden'
          }`}
        >
          {folder.concepts.map((concept: any, index: number) => (
            <div
              className="p-3 text-base font-normal text-[#52525B] cursor-pointer"
              onClick={() => openConceptModal(concept.id)}
              key={index}
            >
              {concept.concept_title}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default ConceptFolder
