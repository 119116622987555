import React, { FC, useEffect, useState } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { ToastContainer, toast, Flip } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import logoImage from '../../assets/auth/logo.svg'
import avatarImage from '../../assets/auth/example-avatar.png'
import bgImage from '../../assets/auth/register-bg-image.png'
import circleImage from '../../assets/auth/register-circle-bg.svg'
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md'

type SomeComponentProps = RouteComponentProps
const Register: FC<SomeComponentProps> = ({ history }) => {
  const [isRegisterFinished, setRegisterFinished] = useState(false)
  const [teamID, setTeamID] = useState('')
  const [isOpenAI, setIsOpenAI] = useState(false)
  const [OPENAI_API_KEY, setOPENAI_API_KEY] = useState('')
  const [AZURE_OPENAI_API_KEY, setAZURE_OPENAI_API_KEY] = useState('')
  const [AZURE_ENDPOINT, setAZURE_ENDPOINT] = useState('')
  const [
    AZURE_OPENAI_GPT4_DEPLOYMENT,
    setAZURE_OPENAI_GPT4_DEPLOYMENT,
  ] = useState('')
  const [
    AZURE_OPENAI_GPT35_DEPLOYMENT,
    setAZURE_OPENAI_GPT35_DEPLOYMENT,
  ] = useState('')
  const [
    AZURE_OPENAI_EMBEDDING_DEPLOYMENT,
    setAZURE_OPENAI_EMBEDDING_DEPLOYMENT,
  ] = useState('')
  const [PINECONE_API_KEY, setPINECONE_API_KEY] = useState('')
  const [PINECONE_ENVIRONMENT, setPINECONE_ENVIRONMENT] = useState('')
  const [PINECONE_INDEX_NAME, setPINECONE_INDEX_NAME] = useState('')
  useEffect(() => {
    //Need to remove when we show sign up page
    // history.push("/login")
  }, [])
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm()
  const submitData = (data: any) => {
    let params = {
      user_name: data.user_name,
      team_name: data.team_name,
      email: data.email,
      password: data.password,
    }
    axios
      .post(process.env.REACT_APP_API_URL + 'api/signup', params)
      .then(function (response: any) {
        toast.success('User created successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: 'my_toast',
        })
        reset()
        setRegisterFinished(true)
        setTeamID(response?.data?.organization_id)
      })
      .catch(function (error: any) {
        toast.error('Something went wrong when creating user', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: 'my_toast',
        })
      })
  }

  const submitConfig = async () => {
    try {
      let validate = false
      if (isOpenAI) {
        if (OPENAI_API_KEY !== '' && PINECONE_API_KEY !== '' && PINECONE_ENVIRONMENT !== '' && PINECONE_INDEX_NAME !== '') {
          validate = true
        } else {
          validate = false
        }
      } else {
        if (AZURE_OPENAI_API_KEY !== '' && AZURE_ENDPOINT !== '' && AZURE_OPENAI_GPT4_DEPLOYMENT !== '' && AZURE_OPENAI_GPT35_DEPLOYMENT !== '' && AZURE_OPENAI_EMBEDDING_DEPLOYMENT !== '' && PINECONE_API_KEY !== '' && PINECONE_ENVIRONMENT !== '' && PINECONE_INDEX_NAME !== '') {
          validate = true
        } else {
          validate = false
        }
      }
      if (!validate) {
        toast.warning('Please input all values for configuration fields', {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: 'my_toast',
        })
        return
      }
      const response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/saveSetting',
        {
          team_id: teamID,
          isOpenAI: isOpenAI,
          OPENAI_API_KEY: OPENAI_API_KEY?.length > 90 ? '' : OPENAI_API_KEY,
          AZURE_OPENAI_API_KEY:
            AZURE_OPENAI_API_KEY?.length > 90 ? '' : AZURE_OPENAI_API_KEY,
          AZURE_ENDPOINT: AZURE_ENDPOINT?.length > 90 ? '' : AZURE_ENDPOINT,
          AZURE_OPENAI_GPT35_DEPLOYMENT:
            AZURE_OPENAI_GPT35_DEPLOYMENT?.length > 90
              ? ''
              : AZURE_OPENAI_GPT35_DEPLOYMENT,
          AZURE_OPENAI_GPT4_DEPLOYMENT:
            AZURE_OPENAI_GPT4_DEPLOYMENT?.length > 90
              ? ''
              : AZURE_OPENAI_GPT4_DEPLOYMENT,
          AZURE_OPENAI_EMBEDDING_DEPLOYMENT:
            AZURE_OPENAI_EMBEDDING_DEPLOYMENT?.length > 90
              ? ''
              : AZURE_OPENAI_EMBEDDING_DEPLOYMENT,
          PINECONE_API_KEY:
            PINECONE_API_KEY?.length > 90 ? '' : PINECONE_API_KEY,
          PINECONE_ENVIRONMENT:
            PINECONE_ENVIRONMENT?.length > 90 ? '' : PINECONE_ENVIRONMENT,
          PINECONE_INDEX_NAME:
            PINECONE_INDEX_NAME?.length > 90 ? '' : PINECONE_INDEX_NAME,
        },
      )
      if (response.status === 200) {
        toast.success('Saved setting successfully!', {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: 'my_toast',
        })
        setTimeout(() => {
          history.push('/')
        }, 1000)
      }
    } catch (error: any) {
      toast.error(<div className='whitespace-pre-line'>{error.response.data.message}</div>, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: 0,
        toastId: "my_toast",
      });
    }
  }
  return (
    <>
      <div className="w-screen h-screen font-Satoshi">
        <div className="flex h-full">
          <div className="h-full flex flex-col justify-between py-[72px] px-24 bg-white w-[700px]">
            <div className="flex items-center gap-2">
              <img
                src={logoImage}
                alt="Logo"
                className="w-12 h-12 rounded-full flex-none"
              />
              <div className="">
                <div className="text-[22px] leading-[27px] tracking-[-3%] text-[#27272A] font-Host-Grotesk font-medium">
                  Profitable Signals
                </div>
                <div className="text-[13px] leading-[16px] tracking-[-2%] text-[#71717A] font-Host-Grotesk font-normal">
                  Researcher
                </div>
              </div>
            </div>
            {
              isRegisterFinished ? <div className="flex flex-col gap-1">
                <div>
                  <div className="text-heading-4 leading-heading-4 text-[#232323] font-bold mb-2">
                    Input your details 👋🏻
                  </div>
                  <div className="text-xl text-black font-normal">
                    Input your openai, pinecone details to enjoy the feature of Profitable Signals.
                    If you do not have that now, you can skip that.
                  </div>
                </div>
                <div className="pb-6 border-b border-[#E4E4E7]">
                  <div className="pb-4">
                    <div className="text-base font-normal text-[#09090B] pl-1 pb-2">
                      Open AI Configuration
                    </div>
                    <div className="flex gap-5">
                      <div
                        className="flex gap-2 items-center cursor-pointer"
                        onClick={() => setIsOpenAI(true)}
                      >
                        {isOpenAI ? (
                          <MdRadioButtonChecked className="text-lg text-[#3B82F6]" />
                        ) : (
                          <MdRadioButtonUnchecked className="text-lg text-[#71717A]" />
                        )}
                        <span className="text-base font-medium text-[#52525B]">
                          OpenAI
                        </span>
                      </div>
                      <div
                        className="flex gap-2 items-center cursor-pointer"
                        onClick={() => setIsOpenAI(false)}
                      >
                        {!isOpenAI ? (
                          <MdRadioButtonChecked className="text-lg text-[#3B82F6]" />
                        ) : (
                          <MdRadioButtonUnchecked className="text-lg text-[#71717A]" />
                        )}
                        <span className="text-base font-medium text-[#52525B]">
                          Azure OpenAI
                        </span>
                      </div>
                    </div>
                  </div>
                  {isOpenAI ? (
                    <div className="flex flex-col gap-2 mb-4">
                      <input
                        id="openai_api_key"
                        type={OPENAI_API_KEY?.length > 90 ? 'password' : ''}
                        className="w-full h-12 rounded-lg bg-[#FCFCFC] border border-[#D4D4D8] px-4 py-3 text-[#3F3F46] text-sm leading-6 font-medium"
                        placeholder="OpenAI API Key"
                        onChange={(e) => {
                          setOPENAI_API_KEY(e.target.value)
                        }}
                        value={OPENAI_API_KEY}
                      />
                    </div>
                  ) : (
                    <div className="flex flex-col gap-2 mb-4">
                      <input
                        id="azure_openai_api_key"
                        type={AZURE_OPENAI_API_KEY?.length > 90 ? 'password' : ''}
                        className="w-full h-12 rounded-lg bg-[#FCFCFC] border border-[#D4D4D8] px-4 py-3 text-[#3F3F46] text-sm leading-6 font-medium"
                        placeholder="Azure OpenAI API Key"
                        onChange={(e) => {
                          setAZURE_OPENAI_API_KEY(e.target.value)
                        }}
                        value={AZURE_OPENAI_API_KEY}
                      />
                      <input
                        id="azure_api_endpoint"
                        type={AZURE_ENDPOINT?.length > 90 ? 'password' : ''}
                        className="w-full h-12 rounded-lg bg-[#FCFCFC] border border-[#D4D4D8] px-4 py-3 text-[#3F3F46] text-sm leading-6 font-medium"
                        placeholder="Azure API Endpoint"
                        onChange={(e) => {
                          setAZURE_ENDPOINT(e.target.value)
                        }}
                        value={AZURE_ENDPOINT}
                      />
                      <input
                        id="gpt4_model_name"
                        type={AZURE_OPENAI_GPT4_DEPLOYMENT?.length > 90 ? 'password' : ''}
                        className="w-full h-12 rounded-lg bg-[#FCFCFC] border border-[#D4D4D8] px-4 py-3 text-[#3F3F46] text-sm leading-6 font-medium"
                        placeholder="GPT4 Model Name"
                        onChange={(e) => {
                          setAZURE_OPENAI_GPT4_DEPLOYMENT(e.target.value)
                        }}
                        value={AZURE_OPENAI_GPT4_DEPLOYMENT}
                      />
                      <input
                        id="gpt35_model_name"
                        type={AZURE_OPENAI_GPT35_DEPLOYMENT?.length > 90 ? 'password' : ''}
                        className="w-full h-12 rounded-lg bg-[#FCFCFC] border border-[#D4D4D8] px-4 py-3 text-[#3F3F46] text-sm leading-6 font-medium"
                        placeholder="GPT3.5 Model Name"
                        onChange={(e) => {
                          setAZURE_OPENAI_GPT35_DEPLOYMENT(e.target.value)
                        }}
                        value={AZURE_OPENAI_GPT35_DEPLOYMENT}
                      />
                      <input
                        id="embedding_name"
                        type={
                          AZURE_OPENAI_EMBEDDING_DEPLOYMENT?.length > 90 ? 'password' : ''
                        }
                        className="w-full h-12 rounded-lg bg-[#FCFCFC] border border-[#D4D4D8] px-4 py-3 text-[#3F3F46] text-sm leading-6 font-medium"
                        placeholder="Embedding Name"
                        onChange={(e) => {
                          setAZURE_OPENAI_EMBEDDING_DEPLOYMENT(e.target.value)
                        }}
                        value={AZURE_OPENAI_EMBEDDING_DEPLOYMENT}
                      />
                    </div>
                  )}
                  <div className="text-base font-normal text-[#09090B] pl-1 pb-1">
                    Pinecone Configuration
                  </div>
                  <div className="flex flex-col gap-2 mb-4">
                    <input
                      id="pinecone_api_key"
                      type={PINECONE_API_KEY?.length > 90 ? 'password' : ''}
                      className="w-full h-12 rounded-lg bg-[#FCFCFC] border border-[#D4D4D8] px-4 py-3 text-[#3F3F46] text-sm leading-6 font-medium"
                      placeholder="Pinecone API Key"
                      onChange={(e) => {
                        setPINECONE_API_KEY(e.target.value)
                      }}
                      value={PINECONE_API_KEY}
                    />
                    <input
                      id="pinecone_environment"
                      type={PINECONE_ENVIRONMENT?.length > 90 ? 'password' : ''}
                      className="w-full h-12 rounded-lg bg-[#FCFCFC] border border-[#D4D4D8] px-4 py-3 text-[#3F3F46] text-sm leading-6 font-medium"
                      placeholder="Pinecone Environment"
                      onChange={(e) => {
                        setPINECONE_ENVIRONMENT(e.target.value)
                      }}
                      value={PINECONE_ENVIRONMENT}
                    />
                    <input
                      id="pinecone_index_name"
                      type={PINECONE_INDEX_NAME?.length > 90 ? 'password' : ''}
                      className="w-full h-12 rounded-lg bg-[#FCFCFC] border border-[#D4D4D8] px-4 py-3 text-[#3F3F46] text-sm leading-6 font-medium"
                      placeholder="Pinecone Index Name"
                      onChange={(e) => {
                        setPINECONE_INDEX_NAME(e.target.value)
                      }}
                      value={PINECONE_INDEX_NAME}
                    />
                  </div>
                  <div className='flex gap-5'>
                    <button className="w-full h-12 bg-[#4682B4] rounded-lg py-3 px-6 flex justify-center items-center mt-[10px]" onClick={() => {
                      history.push('/')
                    }}>
                      <span className="text-base font-bold text-white">
                        Skip
                      </span>
                    </button>
                    <button className="w-full h-12 bg-[#3B82F6] rounded-lg py-3 px-6 flex justify-center items-center mt-[10px]" onClick={() => {
                      submitConfig()
                    }}>
                      <span className="text-base font-bold text-white">
                        Save
                      </span>
                    </button>
                  </div>

                </div>
              </div>
                : <form autoComplete="off" onSubmit={handleSubmit(submitData)}>
                  <div className="flex flex-col gap-8">
                    <div>
                      <div className="text-heading-4 leading-heading-4 text-[#232323] font-bold mb-2">
                        Create New Account 👋🏻
                      </div>
                      <div className="text-xl text-[#969696] font-normal">
                        Sign up to enjoy the feature of Profitable Signals
                      </div>
                    </div>
                    <div className="flex flex-col gap-5">
                      <div className="w-full">
                        <label
                          htmlFor="user_name"
                          className="text-base text-[#27272A] mb-1 ml-1"
                        >
                          Fullname
                        </label>
                        <input
                          type="text"
                          id="user_name"
                          className="w-full h-12 border border-[#A1A1AA] rounded-lg px-4 py-3 text-sm leading-6 text-[#71717A] outline-none"
                          placeholder="Enter your full name"
                          {...register('user_name', {
                            required: 'User name is required!',
                          })}
                        />
                        {errors.user_name && (
                          <div className="text-red-600 text-xs leading-5 no-underline mt-1">
                            {errors.user_name.message}
                          </div>
                        )}
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="team_name"
                          className="text-base text-[#27272A] mb-1 ml-1"
                        >
                          Team Name
                        </label>
                        <input
                          type="text"
                          id="team_name"
                          className="w-full h-12 border border-[#A1A1AA] rounded-lg px-4 py-3 text-sm leading-6 text-[#71717A] outline-none"
                          placeholder="Enter your team name"
                          {...register('team_name', {
                            required: 'Team Name is required!',
                          })}
                        />
                        {errors.team_name && (
                          <div className="text-red-600 text-xs leading-5 no-underline mt-1">
                            {errors.team_name.message}
                          </div>
                        )}
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="email"
                          className="text-base text-[#27272A] mb-1 ml-1"
                        >
                          Email Address
                        </label>
                        <input
                          type="email"
                          id="email"
                          className="w-full h-12 border border-[#A1A1AA] rounded-lg px-4 py-3 text-sm leading-6 text-[#71717A] outline-none"
                          placeholder="Enter your email address"
                          {...register('email', { required: 'Email is required!' })}
                        />
                        {errors.email && (
                          <div className="text-red-600 text-xs leading-5 no-underline mt-1">
                            {errors.email.message}
                          </div>
                        )}
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="password"
                          className="text-base text-[#27272A] mb-1 ml-1"
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          id="password"
                          className="w-full h-12 border border-[#A1A1AA] rounded-lg px-4 py-3 text-sm leading-6 text-[#71717A] outline-none"
                          placeholder="Enter your password"
                          {...register('password', {
                            required: 'Password is required!',
                            minLength: {
                              value: 6,
                              message:
                                'Password should be at least 6 characters long',
                            },
                            pattern: {
                              value: /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
                              message:
                                'Password should contain at least one number and one symbol',
                            },
                          })}
                        />
                        {errors.password && (
                          <div
                            className="text-red-600 text-xs leading-5 no-underline mt-1"
                            style={{ fontSize: 14 }}
                          >
                            {errors.password.message}
                          </div>
                        )}
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="cpassword"
                          className="text-base text-[#27272A] mb-1 ml-1"
                        >
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          id="cpassword"
                          className="w-full h-12 border border-[#A1A1AA] rounded-lg px-4 py-3 text-sm leading-6 text-[#71717A] outline-none"
                          placeholder="Re-enter password "
                          {...register('cpassword', {
                            required: 'Confirm Password is required',

                            validate: (value) =>
                              value === watch('password') ||
                              "Passwords don't match.",
                          })}
                        />
                        {errors.cpassword && (
                          <div
                            className="text-red-600 text-xs leading-5 no-underline mt-1"
                            style={{ fontSize: 14 }}
                          >
                            {errors.cpassword.message}
                          </div>
                        )}
                      </div>
                      <button className="w-full h-12 bg-[#3B82F6] rounded-lg py-3 px-6 flex justify-center items-center mt-[10px]">
                        <span className="text-base font-bold text-white">
                          Sign Up
                        </span>
                      </button>
                    </div>
                    <div className="text-lg leading-[26px] font-medium text-center">
                      Already have an account?{' '}
                      <Link to={'/login'} className="text-[#3B82F6]">
                        Log in
                      </Link>
                    </div>
                  </div>
                </form>
            }


            <div className="h-12"></div>
          </div>
          <div className="flex-1 h-full relative bg-[#DBEAFE] overflow-hidden">
            <div className="mt-[140px] w-full pl-[72px] pr-[100px] relative z-30">
              {/* <div className="text-[32px] leading-10 text-[#232323] font-bold mb-[18px] tracking-[-1px]">
                Lorem ipsum dolor sit amet consectetur. Nulla varius risus augue
                erat morbi.
              </div>
              <div className="flex items-center gap-1">
                <img
                  src={avatarImage}
                  alt="Avatar"
                  className="w-10 h-10 rounded-full flex-none"
                />
                <div className="">
                  <div className="text-sm leading-[22px] font-bold text-black">
                    James Bator
                  </div>
                  <div className="text-[10px] leading-[14px] text-[#838c98] font-normal">
                    CEO of profitable signal
                  </div>
                </div>
              </div> */}
            </div>
            <img
              src={bgImage}
              alt="background"
              className="absolute bottom-0 left-0 h-[64.45%] z-20"
            />
            <img
              src={circleImage}
              alt="circle"
              className="absolute bottom-0 right-0 h-1/2 z-10"
            />
          </div>
        </div>
      </div >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        limit={1}
        transition={Flip}
      />
    </>
  )
}

export default Register
