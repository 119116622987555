import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PrivateRoute from './Auth/PrivateRoute'
import PublicRoute from './Auth/PublicRoute'
import RAG from './pages/rag'
import Register from './pages/auth/Register'
import Login from './pages/auth/Login'
import SetPassword from './pages/auth/SetPassword'
import { RagContextProvider } from './contexts/RagContext'
function App() {
  return (
    <BrowserRouter>
      <RagContextProvider>
        <Switch>
          <PublicRoute exact path="/login" component={Login} />
          <PrivateRoute exact path="/" component={RAG} />
          <PublicRoute exact path="/register" component={Register} />
          <PublicRoute
            exact
            path="/setPassword"
            component={SetPassword}
          ></PublicRoute>
        </Switch>
      </RagContextProvider>
    </BrowserRouter>
  )
}

export default App
