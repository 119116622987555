import React from 'react'
import logo from '../../../assets/auth/logo.svg'
import ProfileComponent from './ProfileComponent'

const ModalHeader: React.FC = () => {
  return (
    <div className="flex items-center justify-between px-6 h-[88px] bg-white">
      <div className={`flex gap-2 items-center overflow-hidden w-fit`}>
        <img
          src={logo}
          alt="Logo"
          className="w-[42px] h-[42px] rounded-full flex-none"
        />
        <div>
          <div className="text-xl leading-6 tracking-[-3%] text-[#27272A] font-medium font-Host-Grotesk">
            Profitable Signals
          </div>
          <div className="text-xs leading-[14px] tracking-[-2%] text-[#71717A] font-normal font-Host-Grotesk">
            Researcher
          </div>
        </div>
      </div>
      <ProfileComponent />
    </div>
  )
}

export default ModalHeader
