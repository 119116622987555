import React, { useEffect, useMemo, useState } from 'react'
import { fiveForceTabList } from './ConceptModal'
import {
  Skeleton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material'
import Markdown from 'react-markdown'
import editPencilIcon from '../../../assets/svg/edit-pen.svg'
import EditFiveForceItemModal from './FIveForces/EditFiveForceItemModal'
import TrashIcon from '../../icons/TrashIcon'
import axios from 'axios'
import DeleteFiveForceItemModal from './FIveForces/DeleteFiveForceItemModal'
import RefreshIcon from '../../icons/RefreshIcon'
import { useUserContext } from '../../../contexts/UserContext'
import DiamondValueIcon from '../../icons/DiamondValueIcon'
import { BiLoaderAlt } from 'react-icons/bi'
import PuzzleIcon from '../../icons/PuzzleIcon'
import LightOnIcon from '../../icons/LightOnIcon'

interface ConceptContentProps {
  conceptData: any
  selectedConceptList: any
  getConceptDetail: () => void
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#040404',
    color: '#e5e5e5',
    maxWidth: 220,
    border: '1px solid #dadde9',
    marginBottom: '8px !important',
    padding: '2px 4px !important',
    fontSize: '11px !important',
  },
}))

const ConceptContent: React.FC<ConceptContentProps> = ({
  conceptData,
  selectedConceptList,
  getConceptDetail,
}) => {
  const { user } = useUserContext()
  const [currentTab, setCurrentTab] = useState('Competitors')
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [loadingItems, setLoadingItems] = useState<any>([])
  const [conceptEditData, setConceptEditData] = useState(conceptData);
  const [regeneratingConcepts, setRegeneratingConcepts] = useState<string[]>([])
  const [showLoading, setShowLoading] = useState<boolean>(false)

  const handleEditFiveForceItem = async (item: any) => {
    console.log(item)
    let tempConceptData = JSON.parse(JSON.stringify(conceptData))
    tempConceptData[selectedConceptList][currentTab][selectedIndex] = item
    let params: any = {
      id: conceptData.id,
      [selectedConceptList]: tempConceptData[selectedConceptList],
    }
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/editConcept',
        params,
      )
      if (response?.status === 200) {
        getConceptDetail()
        setEditModalOpen(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteFiveForceItem = async () => {
    let tempConceptData = JSON.parse(JSON.stringify(conceptData))
    tempConceptData[selectedConceptList][currentTab].splice(selectedIndex, 1)
    let params: any = {
      id: conceptData.id,
      [selectedConceptList]: tempConceptData[selectedConceptList],
    }
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/editConcept',
        params,
      )
      if (response?.status === 200) {
        getConceptDetail()
        setDeleteModalOpen(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleLoadMoreFiveForce = async () => {
    if (!conceptData['FiveForces_Details']) return
    setLoadingItems((prev: any) => {
      return [...prev, currentTab]
    })
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/refreshFiveForces',
        {
          team_id: user?.organizationMemberships[0]?.organization.id,
          count: 6,
          idea: conceptData['FiveForces_Details'][`${currentTab}_Question`],
          key: currentTab,
          id: conceptData.id,
        },
      )
      if (response?.status === 200) {
        setLoadingItems((prev: any) =>
          prev.filter((e: any) => e !== currentTab),
        )
        getConceptDetail()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const generateBrainStormConceptDetails = async (type: string) => {
    try {
      setRegeneratingConcepts((prev) => ([...prev, type]))
      let response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/generateBrainStormConceptDetails',
        {
          brainstorm_id: conceptData.brainstorm_id,
          team_id: user?.organizationMemberships[0]?.organization.id,
          concept: {
            concept_title: conceptEditData.concept_title,
            concept_description: conceptEditData.concept_description,
          },
          type,
        },
      )
      setRegeneratingConcepts((prev) => prev.filter((e) => e !== type))

      setConceptEditData((prev: any) => ({
        ...prev,
        [type]: response.data.response,
      }))
    } catch (error) {
      console.log(error)
    }
  }

  const saveBrainStormConceptDetails = async () => {
    try {
      setShowLoading(true)
      await axios.post(
        process.env.REACT_APP_API_URL + 'api/saveBrainStormConceptDetails',
        {
          concept_id: conceptEditData.id,
          concept_title: conceptEditData.concept_title,
          concept_description: conceptEditData.concept_description,
          problem: conceptEditData.problem,
          need: conceptEditData.need,
          solution: conceptEditData.solution,
          value: conceptEditData.value,
        },
      )
      setShowLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (conceptData) {
      setConceptEditData(conceptData)
    }
  }, [conceptData])

  if (selectedConceptList === 'BusinessModelCanvasJSON') {
    return (
      <div className="bg-white border border-solid border-[#D4D4D8] rounded-2xl max-h-[calc(100vh-325px)] overflow-x-hidden overflow-y-auto">
        <div className="grid grid-cols-5">
          <div className="p-3 border-r border-solid border-[#D4D4D8]">
            <div className="mb-1 text-sm leading-[22px] font-bold text-[#27272A]">
              Key Partner
            </div>
            <div className="text-sm leading-[22px] font-normal text-[#71717A] break-anywhere">
              {conceptData[selectedConceptList]['Key_Partnerships']}
            </div>
          </div>
          <div className="flex flex-col border-r border-solid border-[#D4D4D8]">
            <div className="p-3 border-b border-solid border-[#D4D4D8]">
              <div className="mb-1 text-sm leading-[22px] font-bold text-[#27272A]">
                Key Activity
              </div>
              <div className="text-sm leading-[22px] font-normal text-[#71717A] break-anywhere">
                {conceptData[selectedConceptList]['Key_Activities']}
              </div>
            </div>
            <div className="p-3">
              <div className="mb-1 text-sm leading-[22px] font-bold text-[#27272A]">
                Key Resources
              </div>
              <div className="text-sm leading-[22px] font-normal text-[#71717A] break-anywhere">
                {conceptData[selectedConceptList]['Key_Resources']}
              </div>
            </div>
          </div>
          <div className="p-3 border-r border-solid border-[#D4D4D8]">
            <div className="mb-1 text-sm leading-[22px] font-bold text-[#27272A]">
              Value Propositions
            </div>
            <div className="text-sm leading-[22px] font-normal text-[#71717A] break-anywhere">
              {conceptData[selectedConceptList]['Value_Propositions']}
            </div>
          </div>
          <div className="flex flex-col border-r border-solid border-[#D4D4D8]">
            <div className="p-3 border-b border-solid border-[#D4D4D8]">
              <div className="mb-1 text-sm leading-[22px] font-bold text-[#27272A]">
                Customer Relationships
              </div>
              <div className="text-sm leading-[22px] font-normal text-[#71717A] break-anywhere">
                {conceptData[selectedConceptList]['Customer_Relationships']}
              </div>
            </div>
            <div className="p-3">
              <div className="mb-1 text-sm leading-[22px] font-bold text-[#27272A]">
                Channels
              </div>
              <div className="text-sm leading-[22px] font-normal text-[#71717A] break-anywhere">
                {conceptData[selectedConceptList]['Channels']}
              </div>
            </div>
          </div>
          <div className="p-3">
            <div className="mb-1 text-sm leading-[22px] font-bold text-[#27272A]">
              Customer Segments
            </div>
            <div className="text-sm leading-[22px] font-normal text-[#71717A] break-anywhere">
              {conceptData[selectedConceptList]['Customer_Segments']}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 border-t border-solid border-[#D4D4D8]">
          <div className="p-3 border-r border-solid border-[#D4D4D8]">
            <div className="mb-1 text-sm leading-[22px] font-bold text-[#27272A]">
              Cost Structure
            </div>
            <div className="text-sm leading-[22px] font-normal text-[#71717A] break-anywhere">
              {conceptData[selectedConceptList]['Cost_Structure']}
            </div>
          </div>
          <div className="p-3">
            <div className="mb-1 text-sm leading-[22px] font-bold text-[#27272A]">
              Revenue Streams
            </div>
            <div className="text-sm leading-[22px] font-normal text-[#71717A] break-anywhere">
              {conceptData[selectedConceptList]['Revenue_Streams']}
            </div>
          </div>
        </div>
      </div>
    )
  } else if (selectedConceptList === 'FiveForces') {
    return (
      <div className="w-full">
        <div className="flex items-center gap-2 w-full border-b border-solid border-[#D4D4D8]">
          <div className="flex gap-[10px]">
            {fiveForceTabList.map((fiveFoceTabItem, index) => (
              <div
                className={`px-1 pt-[1px] pb-[11px] cursor-pointer text-sm font-semibold border-b-2 border-solid ${fiveFoceTabItem.key === currentTab
                  ? 'text-[#3B82F6] border-[#3B82F6]'
                  : 'text-[#A1A1AA] border-transparent'
                  }`}
                onClick={() => setCurrentTab(fiveFoceTabItem.key)}
                key={index}
              >
                {fiveFoceTabItem.title}
              </div>
            ))}
          </div>
        </div>
        <div className="w-full bg-[#FCFCFC] border-l border-r border-b border-solid border-[#D4D4D8] p-4 overflow-x-hidden overflow-y-auto h-[calc(100vh-360px)] rounded-b-2xl">
          {loadingItems.find((e: any) => e === currentTab) ? (
            <div className="flex gap-2 w-full flex-col">
              <Skeleton
                height={10}
                sx={{ width: '67%', transform: 'scale(1)', overflow: 'hidden' }}
              />
              <Skeleton
                height={10}
                sx={{ width: '46%', transform: 'scale(1)', overflow: 'hidden' }}
              />
              <Skeleton
                height={10}
                sx={{ width: '37%', transform: 'scale(1)', overflow: 'hidden' }}
              />
              <Skeleton
                height={10}
                sx={{ width: '46%', transform: 'scale(1)', overflow: 'hidden' }}
              />
              <Skeleton
                height={10}
                sx={{ width: '56%', transform: 'scale(1)', overflow: 'hidden' }}
              />
              <Skeleton
                height={10}
                sx={{ width: '52%', transform: 'scale(1)', overflow: 'hidden' }}
              />
              <Skeleton
                height={10}
                sx={{ width: '43%', transform: 'scale(1)', overflow: 'hidden' }}
              />
              <Skeleton
                height={10}
                sx={{ width: '75%', transform: 'scale(1)', overflow: 'hidden' }}
              />
            </div>
          ) : (
            <div className="text-base font-normal text-[#000000cb]">
              {conceptData[selectedConceptList][currentTab].length > 0 &&
                conceptData[selectedConceptList][currentTab].map(
                  (e: any, index: number) => (
                    <div className="mb-6 flex gap-1" key={index}>
                      <div className="flex-1">
                        <div className="font-bold">
                          <span>{e.name}</span>
                        </div>
                        <div className="">{e.relevance}</div>
                        <a
                          className="text-[#3B82F6] underline"
                          href={e.website_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {e.website_url}
                        </a>
                      </div>
                      <div className="flex gap-2">
                        <HtmlTooltip title={<div>Edit</div>} placement="top">
                          <button
                            className="outline-none w-7 h-7 border border-[#D4D4D8] items-center justify-center rounded-lg flex"
                            onClick={() => {
                              setSelectedIndex(index)
                              setEditModalOpen(true)
                            }}
                          >
                            <img
                              src={editPencilIcon}
                              alt=""
                              className="w-5 h-5"
                            />
                          </button>
                        </HtmlTooltip>
                        <HtmlTooltip title={<div>Delete</div>} placement="top">
                          <button
                            className="outline-none w-7 h-7 border border-[#D4D4D8] items-center justify-center rounded-lg flex"
                            onClick={() => {
                              setSelectedIndex(index)
                              setDeleteModalOpen(true)
                            }}
                          >
                            <TrashIcon
                              className="w-5 h-5 flex-none"
                              stroke="#09090B"
                            />
                          </button>
                        </HtmlTooltip>
                      </div>
                    </div>
                  ),
                )}
            </div>
          )}
        </div>
        <EditFiveForceItemModal
          item={conceptData[selectedConceptList][currentTab][selectedIndex]}
          open={editModalOpen}
          handleClose={() => setEditModalOpen(false)}
          handleSubmit={(e) => handleEditFiveForceItem(e)}
        />
        <DeleteFiveForceItemModal
          open={deleteModalOpen}
          handleClose={() => setDeleteModalOpen(false)}
          onDelete={() => handleDeleteFiveForceItem()}
        />
        <HtmlTooltip title={<div>Load More</div>} placement="top">
          <button
            className="outline-none w-7 h-7 border border-[#D4D4D8] items-center justify-center rounded-lg flex absolute bottom-0 left-[72px]"
            onClick={() => handleLoadMoreFiveForce()}
          >
            <RefreshIcon className="w-4 h-4" />
          </button>
        </HtmlTooltip>
      </div>
    )
  } else if (selectedConceptList === 'Overview') {
    return (
      <div className="text-base font-normal text-[#000000cb] c-markdown-text max-h-[calc(100vh-325px)] overflow-x-hidden overflow-y-auto">
        <div className="">
          <div className="flex flex-col gap-4">
            <div className="">
              <label
                htmlFor="brainstorm_concept_title"
                className="ml-1 mb-1 text-base font-medium text-[#09090B]"
              >
                Concept
              </label>
              <input
                type="text"
                id="brainstorm_concept_title"
                name="brainstorm_concept_title"
                className="w-full h-12 rounded-lg outline-none bg-[#FCFCFC] border border-solid border-[#D4D4D8] px-4 py-3 text-sm font-medium text-[#3F3F46]"
                value={conceptEditData.concept_title}
                onChange={(e) =>
                  setConceptEditData((prev: any) => ({
                    ...prev,
                    concept_title: e.target.value,
                  }))
                }
              />
            </div>
            <div className="">
              <label
                htmlFor="brainstorm_concept_description"
                className="ml-1 mb-1 text-base font-medium text-[#09090B]"
              >
                Description
              </label>
              <textarea
                id="brainstorm_concept_description"
                name="brainstorm_concept_description"
                className="w-full h-24 rounded-lg outline-none bg-[#FCFCFC] border border-solid border-[#D4D4D8] px-4 py-3 text-sm font-medium text-[#3F3F46]"
                value={conceptEditData.concept_description}
                onChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement>,
                ) =>
                  setConceptEditData((prev: any) => ({
                    ...prev,
                    concept_description: e.target.value,
                  }))
                }
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="border border-solid border-[#D4D4D8] rounded-lg overflow-hidden p-[14px]">
                <div className="flex flex-col gap-4 h-full">
                  <div className="w-12 h-12 flex items-center justify-center bg-[#F4F4F5] rounded-[4px]">
                    <span className="text-xl text-[#3B82F6] font-medium">
                      <DiamondValueIcon className="w-[20px] h-[18px]" />
                    </span>
                  </div>
                  <div className="">
                    <div className="text-lg font-bold text-[#18181B] mb-2">
                      Problem
                    </div>
                    <textarea
                      className={`text-sm font-medium text-[#71717A] w-full border-none outline-none resize-none bg-transparent ${conceptEditData.problem !== '' ? 'h-[120px]' : 'h-[84px]'}`}
                      value={conceptEditData.problem}
                      onChange={(
                        e: React.ChangeEvent<HTMLTextAreaElement>,
                      ) =>
                        setConceptEditData((prev: any) => ({
                          ...prev,
                          problem: e.target.value,
                        }))
                      }
                    ></textarea>
                  </div>
                  {conceptEditData.problem === '' && (
                    <div className="flex justify-end">
                      <button
                        className="p-0 bg-transparent border-none outline-none flex items-center gap-2"
                        onClick={() =>
                          generateBrainStormConceptDetails('problem')
                        }
                        disabled={Boolean(regeneratingConcepts.find((e) => e === 'problem'))}
                      >
                        {regeneratingConcepts.find((e) => e === 'problem') ?
                          <BiLoaderAlt className="w-[14px] h-[14px] text-[#3B82F6] animate-spin" />
                          :
                          <span className="text-sm font-bold text-[#3B82F6]">
                            Suggest fill
                          </span>
                        }
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="border border-solid border-[#D4D4D8] rounded-lg overflow-hidden p-[14px]">
                <div className="flex flex-col gap-4 h-full">
                  <div className="w-12 h-12 flex items-center justify-center bg-[#F4F4F5] rounded-[4px]">
                    <span className="text-xl text-[#3B82F6] font-medium">
                      <PuzzleIcon className="w-[26px] h-[26px]" />
                    </span>
                  </div>
                  <div className="">
                    <div className="text-lg font-bold text-[#18181B] mb-2">
                      Needs
                    </div>
                    <textarea
                      className={`text-sm font-medium text-[#71717A] w-full border-none outline-none resize-none bg-transparent ${conceptEditData.need !== '' ? 'h-[120px]' : 'h-[84px]'}`}
                      value={conceptEditData.need}
                      onChange={(
                        e: React.ChangeEvent<HTMLTextAreaElement>,
                      ) =>
                        setConceptEditData((prev: any) => ({
                          ...prev,
                          need: e.target.value,
                        }))
                      }
                    ></textarea>
                  </div>
                  {conceptEditData.need === '' && (
                    <div className="flex justify-end">
                      <button
                        className="p-0 bg-transparent border-none outline-none flex items-center gap-2"
                        onClick={() =>
                          generateBrainStormConceptDetails('need')
                        }
                        disabled={Boolean(regeneratingConcepts.find((e) => e === 'need'))}
                      >
                        {regeneratingConcepts.find((e) => e === 'need') ?
                          <BiLoaderAlt className="w-[14px] h-[14px] text-[#3B82F6] animate-spin" />
                          :
                          <span className="text-sm font-bold text-[#3B82F6]">
                            Suggest fill
                          </span>
                        }
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="border border-solid border-[#D4D4D8] rounded-lg overflow-hidden p-[14px]">
                <div className="flex flex-col gap-4 h-full">
                  <div className="w-12 h-12 flex items-center justify-center bg-[#F4F4F5] rounded-[4px]">
                    <span className="text-xl text-[#3B82F6] font-medium">
                      <LightOnIcon className="w-[26px] h-[26px]" />
                    </span>
                  </div>
                  <div className="">
                    <div className="text-lg font-bold text-[#18181B] mb-2">
                      Solutions
                    </div>
                    <textarea
                      className={`text-sm font-medium text-[#71717A] w-full border-none outline-none resize-none bg-transparent ${conceptEditData.solution !== '' ? 'h-[120px]' : 'h-[84px]'}`}
                      value={conceptEditData.solution}
                      onChange={(
                        e: React.ChangeEvent<HTMLTextAreaElement>,
                      ) =>
                        setConceptEditData((prev: any) => ({
                          ...prev,
                          solution: e.target.value,
                        }))
                      }
                    ></textarea>
                  </div>
                  {conceptEditData.solution === '' && (
                    <div className="flex justify-end">
                      <button
                        className="p-0 bg-transparent border-none outline-none flex items-center gap-2"
                        onClick={() =>
                          generateBrainStormConceptDetails('solution')
                        }
                        disabled={Boolean(regeneratingConcepts.find((e) => e === 'solution'))}
                      >
                        {regeneratingConcepts.find((e) => e === 'solution') ?
                          <BiLoaderAlt className="w-[14px] h-[14px] text-[#3B82F6] animate-spin" />
                          :
                          <span className="text-sm font-bold text-[#3B82F6]">
                            Suggest fill
                          </span>
                        }
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="border border-solid border-[#D4D4D8] rounded-lg overflow-hidden p-[14px]">
                <div className="flex flex-col gap-4 h-full">
                  <div className="w-12 h-12 flex items-center justify-center bg-[#F4F4F5] rounded-[4px]">
                    <span className="text-xl text-[#3B82F6] font-medium">
                      <DiamondValueIcon className="w-6 h-5" />
                    </span>
                  </div>
                  <div className="">
                    <div className="text-lg font-bold text-[#18181B] mb-2">
                      Value
                    </div>
                    <textarea
                      className={`text-sm font-medium text-[#71717A] w-full border-none outline-none resize-none bg-transparent ${conceptEditData.value !== '' ? 'h-[120px]' : 'h-[84px]'}`}
                      value={conceptEditData.value}
                      onChange={(
                        e: React.ChangeEvent<HTMLTextAreaElement>,
                      ) =>
                        setConceptEditData((prev: any) => ({
                          ...prev,
                          value: e.target.value,
                        }))
                      }
                    ></textarea>
                  </div>
                  {conceptEditData.value === '' && (
                    <div className="flex justify-end">
                      <button
                        className="p-0 bg-transparent border-none outline-none flex items-center gap-2"
                        onClick={() =>
                          generateBrainStormConceptDetails('value')
                        }
                        disabled={Boolean(regeneratingConcepts.find((e) => e === 'value'))}
                      >
                        {regeneratingConcepts.find((e) => e === 'value') ?
                          <BiLoaderAlt className="w-[14px] h-[14px] text-[#3B82F6] animate-spin" />
                          :
                          <span className="text-sm font-bold text-[#3B82F6]">
                            Suggest fill
                          </span>
                        }
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-4">
              <button
                className={`w-[164px] h-12 rounded-lg bg-[#3B82F6] outline-none flex items-center justify-center gap-2 ${showLoading ? 'opacity-60' : ''}`}
                onClick={() => saveBrainStormConceptDetails()}
                disabled={showLoading}
              >
                <span className="text-white text-base font-bold">
                  Save
                </span>
                {showLoading &&
                  <BiLoaderAlt className="w-6 h-6 text-[#A1A1AA] animate-spin" />
                }
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="text-base font-normal text-[#000000cb] c-markdown-text max-h-[calc(100vh-325px)] overflow-x-hidden overflow-y-auto">
        {conceptData[selectedConceptList] === '' ? (
          <div className="flex gap-2 w-full flex-col">
            <Skeleton
              height={10}
              sx={{ width: '67%', transform: 'scale(1)', overflow: 'hidden' }}
            />
            <Skeleton
              height={10}
              sx={{ width: '46%', transform: 'scale(1)', overflow: 'hidden' }}
            />
            <Skeleton
              height={10}
              sx={{ width: '37%', transform: 'scale(1)', overflow: 'hidden' }}
            />
            <Skeleton
              height={10}
              sx={{ width: '46%', transform: 'scale(1)', overflow: 'hidden' }}
            />
            <Skeleton
              height={10}
              sx={{ width: '56%', transform: 'scale(1)', overflow: 'hidden' }}
            />
            <Skeleton
              height={10}
              sx={{ width: '52%', transform: 'scale(1)', overflow: 'hidden' }}
            />
            <Skeleton
              height={10}
              sx={{ width: '43%', transform: 'scale(1)', overflow: 'hidden' }}
            />
            <Skeleton
              height={10}
              sx={{ width: '75%', transform: 'scale(1)', overflow: 'hidden' }}
            />
          </div>
        ) : (
          <Markdown
            components={{
              a: ({ node, ...props }) => (
                <a target="_blank" rel="noreferrer" {...props} />
              ),
            }}
          >
            {conceptData[selectedConceptList]}
          </Markdown>
        )}
      </div>
    )
  }
}

export default ConceptContent
